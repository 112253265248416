import { Grid, Typography, Container, makeStyles, createStyles, Tooltip } from "@material-ui/core"
import { DateTime } from "luxon"

import { ReactComponent as CalendarIcon } from "./../../../assets/calendar-b.svg"
import { ReactComponent as ClockIcon } from "./../../../assets/clock-b.svg"
import { ReactComponent as HourglassIcon } from "./../../../assets/hourglass-b.svg"
import { ReactComponent as WorkordenIcon } from "./../../../assets/menu-orders-b.svg"
import { ReactComponent as MessageIcon } from "./../../../assets/message-text.svg"
import { formatDate, getMinFormat } from "../../../utils/Util"
import useI18n from "../../../hooks/useI18n"
import { Loader } from "../../common/Loader"
import TranslateIcon from "@mui/icons-material/Translate"
import LibraryAddCheckOutlinedIcon from '@mui/icons-material/LibraryAddCheckOutlined';
import { useCloudTranslation } from "../../../hooks/useCloudTranslation"

interface Props {
  details: any
  images: any[]
  loadingImges: boolean
  isAutoApproved?: boolean
  openImage: () => void
}

const WorkOrderDetailsGrid = ({ details, images, loadingImges, isAutoApproved, openImage }: Props) => {
  const classes = useStyles()
  const { i18n } = useI18n()
  const { translateText } = useCloudTranslation()

  return (
    <div className="bg-case-gray">
      <Container className={classes.container}>
        <Grid container>
          <Grid item xs={6}>
            <div className={classes.gridHeight}>
              <Grid container>
                <Grid item xs={12}>
                  <Typography className={classes.titleFont}>{i18n.t("workOrders.workOrdersDetails")}</Typography>
                </Grid>
                <Grid item xs={12} className="mt-8 flex flex-row items-center">
                  <WorkordenIcon className="mr-3" />
                  <div>
                    <span className={classes.infoFont}>{details?.name}</span>
                    <Typography noWrap className={classes.infoTitle}>
                      {i18n.t("common.tableHeaders.workOrderNo")}
                    </Typography>
                  </div>
                </Grid>
                <Grid item xs={6} className="mt-8 flex flex-row items-center">
                  <LibraryAddCheckOutlinedIcon className="mr-3" style={{color: "#0E2150"}} />
                  <div>
                    <span className={classes.infoFont}>{isAutoApproved ? "Approved" : details?.approval_status}</span>
                    <Typography noWrap className={classes.infoTitle}>
                      {i18n.t("workOrders.approvalStatus")}
                    </Typography>
                  </div>
                </Grid>
                <Grid item xs={6} className="mt-8 flex flex-row items-center">
                  <CalendarIcon className="mr-3" />
                  <div>
                    <span className={classes.infoFont}>{details?.service_date ? formatDate(details?.service_date) : "-"}</span>
                    <Typography noWrap className={classes.infoTitle}>
                      {i18n.t("common.tableHeaders.serviceDate")}
                    </Typography>
                  </div>
                </Grid>
                <Grid item xs={6} className="mt-8 flex flex-row items-center">
                  <ClockIcon className="mr-3" />
                  <div>
                    <span className={classes.infoFont}>{details?.service_date ? DateTime.fromISO(details?.service_date).toFormat("t") : "-"}</span>
                    <Typography noWrap className={classes.infoTitle}>
                      {i18n.t("common.tableHeaders.serviceTime")}
                    </Typography>
                  </div>
                </Grid>
                <Grid item xs={6} className="mt-8 flex flex-row items-center">
                  <HourglassIcon className="mr-3" />
                  <div>
                    <span className={classes.infoFont}>{details?.time_taken_minutes ? getMinFormat(details?.time_taken_minutes) : "-"}</span>
                    <Typography noWrap className={classes.infoTitle}>
                      {i18n.t("common.tableHeaders.durationOnSite")}
                    </Typography>
                  </div>
                </Grid>
              </Grid>
            </div>
          </Grid>
          <Grid item xs={6}>
            <div className={classes.gridHeight}>
              <Grid container>
                <Grid item xs={12}>
                  <Typography className={classes.titleFont}>{i18n.t("workOrders.servicesLogged")}</Typography>
                </Grid>

                <Grid item xs={12}>
                  <div className=" m-3">
                    {!!details?.services?.length &&
                      details?.services.map((service: any, index: any) => (
                        <div key={index} className="flex flex-row items-center font-bold">
                          <div className="w-full">
                            {service?.service_key || "-"}
                            {" / "}
                            {service?.status}
                          </div>
                        </div>
                      ))}
                  </div>
                </Grid>
                <Grid item xs={12} className="mt-8 flex flex-row items-center">
                  <MessageIcon className="ml-3" />
                  <div>
                    <span className={classes.commentsTitle}>{i18n.t("workOrders.comments")}:</span>
                  </div>
                </Grid>

                <Grid item xs={12} className="mt-8 flex flex-row items-center">
                  <div className="flex flex-row items-center justify-between gap-2">
                    <span className={classes.comments}>{details?.comments || "-"}</span>
                    {i18n.locale !== "en" && details?.comments && (
                      <Tooltip title={i18n.t("common.translate")} placement="top-start">
                        <TranslateIcon
                          className="transform cursor-pointer text-right transition duration-75 ease-in-out hover:scale-125"
                          style={{ width: "20px", height: "20px" }}
                          onClick={async (e) => {
                            e.stopPropagation()
                            const translation = await translateText(details?.comments)

                            if (translation) {
                              details.comments = translation || details?.comments
                            }
                          }}
                        />
                      </Tooltip>
                    )}
                  </div>
                </Grid>
              </Grid>
            </div>
          </Grid>

          <Grid item xs={12}>
            <div className={classes.grid}>
              <Grid container>
                <Grid item xs={12}>
                  <Typography className={classes.titleFont}>{i18n.t("common.photos")}</Typography>
                </Grid>

                <Grid item xs={12} className={classes.scrollableImg}>
                  <div className="flex flex-row items-center">
                    {loadingImges ? (
                      <Loader />
                    ) : (
                      <>
                        {!!images?.length &&
                          images.map((img: any, index: any) => (
                            <div key={index} className={classes.imgContainer}>
                              <img className={classes.image + " cursor-pointer"} alt="photo" onClick={() => openImage()} src={img} />
                            </div>
                          ))}
                      </>
                    )}
                  </div>
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>
      </Container>
    </div>
  )
}

const useStyles = makeStyles(() =>
  createStyles({
    listContainer: {
      width: "auto",
      marginLeft: 0,
      padding: "10px",
    },
    location: {
      fontWeight: "bold",
      color: "#1695CB",
    },
    greenStatus: {
      backgroundColor: "#d7f5e3",
      color: "#4e996c",
      borderRadius: "20px",
      padding: "5px 10px",
      fontSize: "10px",
    },
    redStatus: {
      backgroundColor: "#fed5d5",
      color: "#ff3b5f",
      borderRadius: "20px",
      padding: "5px 10px",
      fontSize: "10px",
    },
    yellowStatus: {
      backgroundColor: "#ffd978",
      color: "#665730",
      borderRadius: "20px",
      padding: "5px 10px",
      fontSize: "10px",
    },
    detailsGrid: {
      border: "1px solid #E0E0E0",
      borderRadius: "5px",
      margin: "5px",
      background: "#f5f7ff",
    },
    showTitle: {
      width: "auto",
      marginTop: "10px",
      marginLeft: "10px",
      marginBottom: "20px",
    },
    titleFont: {
      fontSize: "22px",
      fontWeight: 800,
      lineHeight: "113%",
      color: "#000",
      letterSpacing: "-1px",
      marginBottom: "20px",
    },
    infoFont: {
      color: "#0F2150",
      fontWeight: "bold",
      fontSize: 20,
      marginLeft: "30px",
    },
    commentsTitle: {
      color: "#0F2150",
      fontWeight: "bold",
      fontSize: 15,
      marginLeft: "0px",
    },
    comments: {
      fontSize: 15,
      marginLeft: "10px",
    },
    infoTitle: {
      fontSize: "13px",
      lineHeight: "113%",
      color: "gray",
      letterSpacing: "-1px",
      marginBottom: "10px",
      marginLeft: "30px",
    },
    grid: {
      border: "1px solid #E0E0E0",
      borderRadius: "5px",
      margin: "5px",
      padding: "5px",
      background: "#FFFFFF",
    },
    container: {
      maxWidth: "100%",
    },
    image: {
      maxHeight: "100px",
    },
    imgContainer: {
      position: "relative",
      maxWidth: "150px",
      maxHeight: "100px",
      margin: "0px 5px 5px 0px",
      minWidth: "fit-content",
    },
    imgButton: {
      background: "red",
      top: 0,
      right: 0,
      border: "none",
      cursor: "pointer",
      position: "absolute",
      textAlign: "center",
      borderRadius: "5px",
      height: "25px",
      minWidth: "25px",
    },
    scrollableImg: {
      width: "500px",
      overflowY: "hidden",
    },
    styleImg: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    iframeBlock: {
      width: "60%",
      margin: "5px",
      backgroundColor: "white",
      position: "absolute",
    },
    gridHeight: {
      border: "1px solid #E0E0E0",
      borderRadius: "5px",
      margin: "5px",
      padding: "5px",
      background: "#FFFFFF",
      minHeight: "95.5%",
    },
    carouselImg: {
      maxHeight: "85vh",
    },
    paddingRight: {
      paddingRight: "1px",
    },
  })
)

export default WorkOrderDetailsGrid
