export const translations = {
  en: {
    common: {
      en: "English",
      es: "Spanish",
      fr: "French",
      yes: "Yes",
      no: "No",
      translate: "Translate",
      language: "Language",
      search: "Search",
      empty: "Empty",
      services: "Services",
      photos: "Photos",
      upload: "Upload",
      update: "Update",
      delete: "Delete",
      success: "Success",
      error: "Error",
      cancel: "Cancel",
      save: "Save",
      edit: "Edit",
      tableHeaders: {
        status: "Status",
        service: "Service",
        site: "Site",
        siteName: "Site Name",
        distance: "Distance",
        bidDeadline: "Bid Deadline",
        startDate: "Start Date",
        endDate: "End Date",
        createdDate: "Created Date",
        frequency: "Frequency",
        siteStatus: "Site Status",
        completedWOs: "Completed WOs",
        proximity: "Proximity",
        lastServiceDate: "Last Service Date",
        serviceDate: "Service Date",
        serviceTime: "Service Time",
        workOrderNo: "Work Order #",
        serviceSummary: "Service Summary",
        dateReported: "Date Reported",
        checkinTime: "Check-In Time",
        timeTakenOnSite: "Time Taken On Site",
        durationOnSite: "Duration on-site",
        name: "Name",
        roles: "Role(s)",
        firstLogin: "First Login",
        lastLogin: "Last Login",
        action: "Action",
      },
      pagination: {
        showingCount: "Showing  %{actual} of %{total}",
        show: "Show",
      },
      modal: {
        inviteUser: {
          somethingWentWrong: "Something went wrong, please try again",
          inviteUser: "Invite User",
          organization: "Organization",
          emailPhone: "Email / Phone Number",
          provideEmailOrPhone: "Please enter a valid email or phone number.",
        },
        updateUser: {
          mustProvideEmailOrPhone: "You must provide either an email or a phone number",
          updateUser: "Update User",
          firstName: "First Name",
          lastName: "Last Name",
          email: "Email",
          invalidEmail: "Please enter a valid email.",
          phoneNumber: "Phone Number",
          invalidPhone: "Please enter a valid phone number.",
        },
        updateRole: {
          updateRole: "Update Role",
        },
        deleteUser: {
          deleteUser: "Delete User",
          areYouSure: "Are you sure you want to delete the user",
          actionCannotBeUndone: "This action cannot be undone.",
        },
      },
      actionMenu: {
        editProfile: "Edit Profile",
        editOrg: "Edit Organization",
        editRole: "Edit Role",
      },
      menuBar: {
        home: "Home",
        bids: "Bids",
        sites: "Sites",
        workOrders: "Work Orders",
        admin: "Admin",
        validation: "Validation",
        compliance: "Compliance",
        profile: "Profile",
        team: "Team",
        contactUs: "Contact Us",
        signUpForDirectPayment: "Sign Up For Direct Payment",
        logOut: "Logout",
      },
      monthShortNames: {
        jan: "Jan",
        feb: "Feb",
        mar: "Mar",
        apr: "Apr",
        may: "May",
        jun: "Jun",
        jul: "Jul",
        aug: "Aug",
        sep: "Sep",
        oct: "Oct",
        nov: "Nov",
        dec: "Dec",
      },
    },
    dashboard: {
      insuranceStatus: "Insurance Status",
      compliant: "Compliant",
      noncompliant: "Noncompliant",
      clickHereForMoreDetails: "Click here for more details",
      bids: "Bids",
      openBids: "Open Bids",
      pendingBids: "Pending Bids",
      awardedBids: "Awarded Bids",
      performance: "Performance",
      activeSites: "Active Sites",
      completedWorkOrders: "Completed Work Orders",
      yourSite: "Your Site",
      yourSites: "Your Sites",
    },
    breadcrumbs: {
      home: "Home",
      bidListing: "Bid Listing",
      bidDetails: "Bid Details",
      siteListing: "Site Listing",
      siteDetails: "Site Detail",
      workOrderListing: "Work Orders Listing",
      complianceDashboard: "Compliance Dashboard",
      profile: "Profile",
      team: "Team",
      contactUs: "Contact Us",
      directPaymentSignIn: "Direct Payment Sign In",
    },
    bids: {
      bidDetails: "Bid Details",
      awarded: "Awarded",
      accepted: "Accepted",
      declined: "Declined",
      notawarded: "Not Awarded",
      terminated: "Terminated",
      noresponse: "No Response",
      openbid: "Open Bid",
      expired: "Expired",
      filterTypes: {
        all: "All Bids",
        awardedBids: "Awarded Bids",
        notAwardedBids: "Not Awarded Bids",
        declinedBids: "Declined Bids",
        acceptedBids: "Accepted Bids",
        openBids: "Open Bids",
        counterBids: "Counter Bids",
        pendingBids: "Pending Bids",
      },
      details: {
        iAccept: "I Accept",
        counterOfferMessage:
          "By clicking I Accept, you are agreeing to all current Case Offers which have not been countered, in addition to the ones which have been countered.",
        attentiveClickMessage: "Click To See Square Footage",
        contractStartDate: "Contract Start Date",
        contractEndDate: "Contract End Date",
        serviceOverview: "Service Overview",
        acceptAll: "Accept All",
        declineAll: "Decline All",
        submitCounter: "Submit Counter",
        increment: "Increment",
        makeAnOffer: "Make an Offer",
        accepted: "Accepted",
        counter: "Counter",
        caseOffer: "Case Offer",
        additionalInformation: "Additional Information",
        customerContract: "Customer Contract",
        specialRequirements: "Special Requirements",
        siteSpecificNotes: "Site Specific Notes",
        serviceDetails: {
          title: "Service Details",
          serviceTimeline: "Service Timeline",
          expectedDays: "Expected Days",
        },
      },
    },
    sites: {
      siteDetails: "Site Details",
      totalActiveSites: "Total Active Sites",
      wosCompleted: "WOs Completed",
      searchBySiteName: "Search by site name",
      filterTypes: {
        allStatuses: "All Statuses",
        active: "Active",
        inactive: "Inactive",
      },
      details: {
        lastServiced: "Last Serviced",
        workOrders: "Work Orders",
        caseContacts: "Case Contacts",
        noPhoneNumber: "No Phone Number",
        noEmail: "No Email",
      },
    },
    workOrders: {
      yourWorkOrders: "Your Work Orders",
      workOrdersDetails: "Work Orders Details",
      servicesLogged: "Services Logged",
      comments: "Comments",
      searchByWorkOrderNumber: "Search by work order number",
      approvalStatus: "Approval Status",
    },
    compliance: {
      uploadError: "Upload Unsuccessful. Try again or contact your Partner Relations Representative for assistance",
      uploadSuccess: "File Uploaded Succesfully",
      insuranceStatus: "Insurance Status",
      noStatusProvided: "No status provided",
      sampleSnowCertificate: "Sample Snow Certificate",
      provideToInsuranceAgent: "Please provide to your insurance agent to match exactly",
      nonComplianceReason: "Non-Compliance Reason",
      complianceNotes: "Compliance Notes",
      complianceUploads: "Compliance Uploads",
      generalLiabilityInsurance: "General Liability Insurance",
      autoInsurance: "Auto Insurance",
      workmansCompInsurance: "Workmans Comp Insurance",
      w9onFile: "W9 on file?",
      w9note: "(W9 applicable for US based entities only.)",
      uploadW9: "Upload W9",
      glEndDate: "GL End Date",
      glCarrier: "GL Carrier",
      glAgentName: "GL Agent Name",
      glAgentEmail: "GL Agent Email",
      glAgentPhone: "GL Agent Phone",
      autoPolicyEndDate: "Auto Policy End Date",
      taxID1099: "Tax ID 1099",
      companyDiversity: "Company Diversity",
      uploadDEI: "Upload DEI",
      uploadDiverseCertification:
        "If you've chosen an option besides Decline to Identify or None of the Above, please upload your diverse certification here",
      diversityChecks: {
        MBE: {
          title: "Minority-Owned Business Enterprise",
          alias: "MBE",
          details:
            "MBE - designation for businesses which are at least 51% owned, operated and controlled on a daily basis by one or more ethnic minorities.",
        },
        VBE: {
          title: "Veteran-Owned Business Enterprise",
          alias: "VBE",
          details:
            "VBE - At least 51 percent of the business must be directly and unconditionally owned by one or more veteran(s) or service-disabled veteran(s)",
        },
        WBE: {
          title: "Woman-Owned Business Enterprise",
          alias: "WBE",
          details:
            "WBE - is a designation for businesses which are at least 51% owned, operated and controlled on a daily basis by a women or women.",
        },
        LGBTQ: {
          title: "LGBTQ+",
          alias: "LGBTQ+",
          details: "LGBTQ+ a company-level diversity certification verifying that a business is majority-owned by an LGBTQ+ individual",
        },
        IABE: {
          title: "Indigenous/Aboriginal-Owned Business Enterprise",
          alias: "IABE",
          details: "IABE - Indigenous/Aboriginal-Owned Business Enterprise.",
        },
        none: {
          title: "None of the Above",
          alias: "None of the Above",
          details: "I am answering that I fall into none of the above categories",
        },
        decline: {
          title: "Decline to Identify",
          alias: "Decline to Identify",
          details: "I prefer not to answer",
        },
      },
    },
    profile: {
      equipment: "Equipment",
      services: "Services",
      personalInfo: "Personal Info",
      providePersonalInfo: "Provide your personal and contact information",
      firstName: "First Name",
      lastName: "Last Name",
      phoneNumber: "Phone Number",
      email: "Email",
      companyInfo: "Company Info",
      provideCompanyInfo: "Provide your company information",
      companyName: "Company Name",
      companyAddress: "Company Address",
      companyWebsite: "Company Website",
      optOutWOConfirmationEmails: "Opt Out of WO Confirmation Emails",
      equipmentTab: {
        fleetInformation: "Fleet Information",
        provideNumber: "Provide the number of each piece of equipment you have in your fleet",
        trucks: "Truck(s)",
        sanderDeicers: "Sander/Deicer(s)",
        loaders: "Loader(s)",
        skidSteers: "Skid Steer(s)",
        pushers: "Pusher(s)",
      },
    },
    team: {
      myTeam: "My Team",
    },
    contactUs: {
      supportOrJoinCaseTeam: "For support or to join the CASE team",
      caseContacts: "CASE Contacts",
      callUs: "Call Us",
      emailUs: "Email Us",
      visitUs: "Visit Us",
    },
    directPayment: {
      pleaseFillOutTheForm: "Please fill out the form",
      somethingWentWrong: "Something went wrong, please try again",
      anErrorOcuredWhileProcessingTheFile: "An error occurred while processing the file, please try again",
      anErrorOcuredWhileSubmittingTheForm: "An error occurred while submitting the form, please try again",
      notAvailable: "Not Available",
      directPaymentIsNotAvailableInUS:
        "Direct payment is not available in the US. Please use the link provided in the Sidebar Menu to access to the Direct Payment Form.",
      enrollInDirectDepositToday: "Enroll in Direct Deposit Today",
      enjoyFasterMoreSecureAccess:
        "Enjoy faster, more secure access to your funds by enrolling in direct deposit. Before you begin, please ensure you have your bank's routing number and your account number handy. These can be found on a personal check, bank statement, or by contacting your bank.",
      thanks: "Thank you!",
      thanksMessage: "A member of our team will follow up to confirm your details with 2-3 business days",
      bankName: "Bank Name / Financial Institution",
      bankAddress: "Bank Address",
      transitNumber: "Transit #",
      bankNumber: "Bank #",
      accountNumber: "Account Number",
      uploadVoidedCheck: "Upload a copy of a voided check or a Pre-Authorized Payment Form from your bank",
      submit: "Submit",
    },
  },
  fr: {
    common: {
      en: "Anglais",
      es: "Espagnole",
      fr: "Français",
      yes: "Oui",
      no: "Non",
      translate: "Traduire",
      language: "Langue",
      search: "Rechercher",
      empty: "Vide",
      services: "Services",
      photos: "Photos",
      upload: "Téléverser",
      update: "Mettre à jour",
      delete: "Supprimer",
      success: "Succès",
      error: "Erreur",
      cancel: "Annuler",
      save: "Enregistrer",
      edit: "Modifier",
      tableHeaders: {
        status: "Statut",
        service: "Service",
        site: "Site",
        siteName: "Nom du site",
        distance: "Distance",
        bidDeadline: "Date limite de l'offre",
        startDate: "Date de début",
        endDate: "Date de fin",
        createdDate: "Date de création",
        frequency: "Fréquence",
        siteStatus: "Statut du site",
        completedWOs: "WO terminés",
        proximity: "Proximité",
        lastServiceDate: "Date du dernier service",
        serviceDate: "Date du service",
        serviceTime: "Heure du service",
        workOrderNo: "Numéro de bon de travail",
        serviceSummary: "Résumé du service",
        dateReported: "Date signalée",
        checkinTime: "Heure d'enregistrement",
        timeTakenOnSite: "Temps passé sur site",
        durationOnSite: "Durée sur site",
        name: "Nom",
        roles: "Rôle(s)",
        firstLogin: "Première connexion",
        lastLogin: "Dernière connexion",
        action: "Action",
      },
      pagination: {
        showingCount: "Affichage de %{actual} sur %{total}",
        show: "Afficher",
      },
      modal: {
        inviteUser: {
          somethingWentWrong: "Quelque chose a mal tourné, veuillez réessayer",
          inviteUser: "Inviter un utilisateur",
          organization: "Organisation",
          emailPhone: "Email / Numéro de téléphone",
          provideEmailOrPhone: "Veuillez entrer un email ou un numéro de téléphone valide.",
        },
        updateUser: {
          mustProvideEmailOrPhone: "Vous devez fournir soit un email, soit un numéro de téléphone",
          updateUser: "Mettre à jour l'utilisateur",
          firstName: "Prénom",
          lastName: "Nom de famille",
          email: "Email",
          invalidEmail: "Veuillez entrer un email valide.",
          phoneNumber: "Numéro de téléphone",
          invalidPhone: "Veuillez entrer un numéro de téléphone valide.",
        },
        updateRole: {
          updateRole: "Mettre à jour le rôle",
        },
        deleteUser: {
          deleteUser: "Supprimer l'utilisateur",
          areYouSure: "Êtes-vous sûr de vouloir supprimer l'utilisateur",
          actionCannotBeUndone: "Cette action ne peut pas être annulée.",
        },
      },
      actionMenu: {
        editProfile: "Modifier le profil",
        editOrg: "Modifier l'organisation",
        editRole: "Modifier le rôle",
      },
      menuBar: {
        home: "Accueil",
        bids: "Offres",
        sites: "Sites",
        workOrders: "Bons de travail",
        admin: "Admin",
        validation: "Validation",
        compliance: "Conformité",
        profile: "Profil",
        team: "Équipe",
        contactUs: "Nous contacter",
        signUpForDirectPayment: "Inscrivez-vous pour le paiement direct",
        logOut: "Déconnexion",
      },
      monthShortNames: {
        jan: "Jan",
        feb: "Fév",
        mar: "Mar",
        apr: "Avr",
        may: "Mai",
        jun: "Juin",
        jul: "Juil",
        aug: "Août",
        sep: "Sep",
        oct: "Oct",
        nov: "Nov",
        dec: "Déc",
      },
    },
    dashboard: {
      insuranceStatus: "Statut de l'assurance",
      compliant: "Conforme",
      noncompliant: "Non conforme",
      clickHereForMoreDetails: "Cliquez ici pour plus de détails",
      bids: "Offres",
      openBids: "Offres ouvertes",
      pendingBids: "Offres en attente",
      awardedBids: "Offres attribuées",
      performance: "Performance",
      activeSites: "Sites actifs",
      completedWorkOrders: "Bons de travail terminés",
      yourSite: "Vos site",
      yourSites: "Vos sites",
    },
    breadcrumbs: {
      home: "Accueil",
      bidListing: "Liste des offres",
      bidDetails: "Détails de l'offre",
      siteListing: "Liste des sites",
      siteDetails: "Détails du site",
      workOrderListing: "Liste des bons de travail",
      complianceDashboard: "Tableau de conformité",
      profile: "Profil",
      team: "Équipe",
      contactUs: "Nous contacter",
      directPaymentSignIn: "Connexion au paiement direct",
    },
    bids: {
      bidDetails: "Détails de l'offre",
      awarded: "Attribué",
      accepted: "Accepté",
      declined: "Refusé",
      notawarded: "Non attribué",
      terminated: "Résilié",
      noresponse: "Aucune réponse",
      openbid: "Offre ouverte",
      expired: "Expiré",
      filterTypes: {
        all: "Toutes les offres",
        awardedBids: "Offres attribuées",
        notAwardedBids: "Offres non attribuées",
        declinedBids: "Offres refusées",
        acceptedBids: "Offres acceptées",
        openBids: "Offres ouvertes",
        counterBids: "Offres contre-proposées",
        pendingBids: "Offres en attente",
      },
      details: {
        iAccept: "J'accepte",
        counterOfferMessage:
          "En cliquant sur J'accepte, vous acceptez toutes les offres actuelles qui n'ont pas été contre-proposées, en plus de celles qui ont été contre-proposées.",
        attentiveClickMessage: "Cliquez pour voir la superficie",
        contractStartDate: "Date de début du contrat",
        contractEndDate: "Date de fin du contrat",
        serviceOverview: "Aperçu du service",
        acceptAll: "Tout accepter",
        declineAll: "Tout refuser",
        submitCounter: "Soumettre une contre-proposition",
        increment: "Incrément",
        makeAnOffer: "Faire une offre",
        accepted: "Accepté",
        counter: "Contre-proposition",
        caseOffer: "Offre de cas",
        additionalInformation: "Informations supplémentaires",
        customerContract: "Contrat client",
        specialRequirements: "Exigences spéciales",
        siteSpecificNotes: "Notes spécifiques au site",
        serviceDetails: {
          title: "Détails du service",
          serviceTimeline: "Chronologie du service",
          expectedDays: "Jours attendus",
        },
      },
    },
    sites: {
      siteDetails: "Détails du site",
      totalActiveSites: "Total des sites actifs",
      wosCompleted: "WO terminés",
      searchBySiteName: "Rechercher par nom de site",
      filterTypes: {
        allStatuses: "Tous les statuts",
        active: "Actif",
        inactive: "Inactif",
      },
      details: {
        lastServiced: "Dernier service",
        workOrders: "Bons de travail",
        caseContacts: "Contacts du cas",
        noPhoneNumber: "Pas de numéro de téléphone",
        noEmail: "Pas d'email",
      },
    },
    workOrders: {
      yourWorkOrders: "Vos bons de travail",
      workOrdersDetails: "Détails des bons de travail",
      servicesLogged: "Services enregistrés",
      comments: "Commentaires",
      searchByWorkOrderNumber: "Rechercher par numéro de bon de travail",
      approvalStatus: "Statut d'approbation",
    },
    compliance: {
      uploadError: "Téléversement échoué. Réessayez ou contactez votre représentant des relations partenaires pour obtenir de l'aide",
      uploadSuccess: "Fichier téléversé avec succès",
      insuranceStatus: "Statut de l'assurance",
      noStatusProvided: "Aucun statut fourni",
      sampleSnowCertificate: "Exemple de certificat de neige",
      provideToInsuranceAgent: "Veuillez fournir à votre agent d'assurance pour correspondre exactement",
      nonComplianceReason: "Raison de non-conformité",
      complianceNotes: "Notes de conformité",
      complianceUploads: "Téléversements de conformité",
      generalLiabilityInsurance: "Assurance responsabilité civile",
      autoInsurance: "Assurance automobile",
      workmansCompInsurance: "Assurance contre les accidents du travail",
      w9onFile: "W9 au dossier?",
      uploadW9: "Téléverser W9",
      glEndDate: "Date de fin GL",
      glCarrier: "Transporteur GL",
      glAgentName: "Nom de l'agent GL",
      glAgentEmail: "Email de l'agent GL",
      glAgentPhone: "Téléphone de l'agent GL",
      autoPolicyEndDate: "Date de fin de la police d'assurance auto",
      taxID1099: "Identifiant fiscal 1099",
      companyDiversity: "Diversité de l'entreprise",
      uploadDEI: "Téléverser DEI",
      uploadDiverseCertification:
        "Si vous avez choisi une option autre que Refuser de s'identifier ou Aucun des précédents, veuillez téléverser votre certification de diversité ici",
      diversityChecks: {
        MBE: {
          title: "Entreprise appartenant à une minorité",
          alias: "MBE",
          details:
            "MBE - désignation pour les entreprises dont au moins 51% sont détenues, exploitées et contrôlées quotidiennement par une ou plusieurs minorités ethniques.",
        },
        VBE: {
          title: "Entreprise appartenant à un vétéran",
          alias: "VBE",
          details:
            "VBE - Au moins 51 pour cent de l'entreprise doit être directement et inconditionnellement détenue par un ou plusieurs vétérans ou vétérans handicapés de service.",
        },
        WBE: {
          title: "Entreprise appartenant à une femme",
          alias: "WBE",
          details:
            "WBE - désignation pour les entreprises dont au moins 51% sont détenues, exploitées et contrôlées quotidiennement par une ou plusieurs femmes.",
        },
        LGBTQ: {
          title: "LGBTQ+",
          alias: "LGBTQ+",
          details:
            "LGBTQ+ - certification de diversité au niveau de l'entreprise vérifiant qu'une entreprise est majoritairement détenue par une personne LGBTQ+.",
        },
        IABE: {
          title: "Entreprise appartenant à des autochtones/aborigènes",
          alias: "IABE",
          details: "IABE - Entreprise appartenant à des autochtones/aborigènes.",
        },
        none: {
          title: "Aucune des options ci-dessus",
          alias: "Aucune des options ci-dessus",
          details: "Je réponds que je ne fais partie d'aucune des catégories ci-dessus.",
        },
        decline: {
          title: "Refuse de s'identifier",
          alias: "Refuse de s'identifier",
          details: "Je préfère ne pas répondre.",
        },
      },
    },
    profile: {
      equipment: "Équipement",
      services: "Services",
      personalInfo: "Informations personnelles",
      providePersonalInfo: "Fournissez vos informations personnelles et de contact",
      firstName: "Prénom",
      lastName: "Nom de famille",
      phoneNumber: "Numéro de téléphone",
      email: "Email",
      companyInfo: "Informations sur l'entreprise",
      provideCompanyInfo: "Fournissez les informations de votre entreprise",
      companyName: "Nom de l'entreprise",
      companyAddress: "Adresse de l'entreprise",
      companyWebsite: "Site Web de l'entreprise",
      optOutWOConfirmationEmails: "Désactiver les emails de confirmation de WO",
      equipmentTab: {
        fleetInformation: "Informations sur la flotte",
        provideNumber: "Fournissez le nombre de chaque équipement que vous avez dans votre flotte",
        trucks: "Camion(s)",
        sanderDeicers: "Épandage/Salerie(s)",
        loaders: "Chargeuse(s)",
        skidSteers: "Chargeur compact(s)",
        pushers: "Pousseur(s)",
      },
    },
    team: {
      myTeam: "Mon équipe",
    },
    contactUs: {
      supportOrJoinCaseTeam: "Pour le support ou pour rejoindre l'équipe CASE",
      caseContacts: "Contacts CASE",
      callUs: "Appelez-nous",
      emailUs: "Envoyez-nous un email",
      visitUs: "Visitez-nous",
    },
    directPayment: {
      pleaseFillOutTheForm: "Veuillez remplir le formulaire",
      somethingWentWrong: "Quelque chose s'est mal passé, veuillez réessayer",
      anErrorOcuredWhileProcessingTheFile: "Une erreur s'est produite lors du traitement du fichier, veuillez réessayer",
      anErrorOcuredWhileSubmittingTheForm: "Une erreur s'est produite lors de la soumission du formulaire, veuillez réessayer",
      notAvailable: "Non disponible",
      directPaymentIsNotAvailableInUS:
        "Le paiement direct n'est pas disponible aux États-Unis. Veuillez utiliser le lien fourni dans le menu latéral pour accéder au formulaire de paiement direct.",
      enrollInDirectDepositToday: "Inscrivez-vous au dépôt direct aujourd'hui",
      enjoyFasterMoreSecureAccess:
        "Profitez d'un accès plus rapide et plus sécurisé à vos fonds en vous inscrivant au dépôt direct. Avant de commencer, assurez-vous d'avoir le numéro de routage de votre banque et votre numéro de compte à portée de main. Vous pouvez les trouver sur un chèque personnel, un relevé bancaire ou en contactant votre banque.",
      thanks: "Merci!",
      thanksMessage: "Un membre de notre équipe vous contactera pour confirmer vos coordonnées dans un délai de 2 à 3 jours ouvrables",
      bankName: "Nom de la banque / Institution financière",
      bankAddress: "Adresse de la banque",
      transitNumber: "Numéro de transit",
      bankNumber: "Numéro de banque",
      accountNumber: "Numéro de compte",
      uploadVoidedCheck: "Téléchargez une copie d'un chèque annulé ou d'un formulaire de paiement préautorisé de votre banque",
      submit: "Soumettre",
    },
  },
}
