import { NavLink } from "react-router-dom"
import FactCheckOutlinedIcon from "@mui/icons-material/FactCheckOutlined"
import OpenInNewIcon from "@mui/icons-material/OpenInNew"
import { ReactComponent as AnnotationCheck } from "./../../assets/annotation-check.svg"
import { ReactComponent as WorkordenIcon } from "./../../assets/menu-orders.svg"
import { ReactComponent as BidIcon } from "./../../assets/menu-bids.svg"
import { ReactComponent as MarkerIcon } from "./../../assets/menu-sites.svg"
import { ReactComponent as UsersIcon } from "./../../assets/users.svg"
import { ReactComponent as AdminIcon } from "./../../assets/users-edit.svg"
import { ReactComponent as UserIcon } from "./../../assets/user.svg"
import { ReactComponent as LogoutIcon } from "./../../assets/log-out.svg"
import { ReactComponent as PhoneIcon } from "./../../assets/phone-w.svg"
import logo from "./../../assets/logo.svg"
import { useAuth, useActiveUser } from "../../providers/AuthProvider"
import useI18n from "../../hooks/useI18n"

const Sidebar = ({ children }: any) => {
  const { isCanada, logout } = useAuth()
  const { permissions } = useActiveUser()
  const { i18n } = useI18n()

  const handleLogout = () => logout()

  const menuItem = [
    {
      path: "/bids",
      name: i18n.t("common.menuBar.bids"),
      icon: <BidIcon />,
    },
    {
      path: "/sites",
      name: i18n.t("common.menuBar.sites"),
      icon: <MarkerIcon />,
    },
    {
      path: "/workorders",
      name: i18n.t("common.menuBar.workOrders"),
      icon: <WorkordenIcon />,
    },
    {
      path: "/admin",
      name: i18n.t("common.menuBar.admin"),
      icon: <AdminIcon />,
    },
    {
      path: "/validation",
      name: i18n.t("common.menuBar.validation"),
      icon: <FactCheckOutlinedIcon />,
    },
    {
      path: "/compliance",
      name: i18n.t("common.menuBar.compliance"),
      icon: <AnnotationCheck />,
    },
  ]

  const menuItemLogout = [
    {
      path: "/login",
      name: i18n.t("common.menuBar.logOut"),
      icon: <LogoutIcon />,
    },
  ]

  const menuItemBottom = [
    {
      path: "/profile",
      name: i18n.t("common.menuBar.profile"),
      icon: <UserIcon />,
    },
    {
      path: "/team",
      name: i18n.t("common.menuBar.team"),
      icon: <UsersIcon />,
    },
    {
      path: "/contact-us",
      name: i18n.t("common.menuBar.contactUs"),
      icon: <PhoneIcon />,
    },
    {
      path: !isCanada ? "https://go.paymode.com/CaseFMS/G-NB6MHN5KV" : "/direct-payment",
      isHyperLink: !isCanada,
      name: i18n.t("common.menuBar.signUpForDirectPayment"),
      icon: <OpenInNewIcon />,
    },
  ]

  // const menuAdminItem = [
  //   {
  //     path: "/admin",
  //     name: "Admin",
  //     icon: <AdminIcon />,
  //   },
  //   {
  //     path: "/validation",
  //     name: "Validation",
  //     icon: <FactCheckOutlinedIcon />,
  //   },
  // ]

  return (
    <div className="sidebar-container">
      <div className={"w-52" + " sidebar overflow-y-auto"}>
        <div>
          <NavLink to={"/"}>
            <div
              style={{
                marginLeft: "0px",
              }}
              className="bars logo-case cursor-pointer"
            >
              <img
                src={logo}
                alt="logo"
                style={{
                  height: "100px",
                }}
              />
            </div>
          </NavLink>
        </div>
        <div>
          {menuItem.map(
            (item, index) =>
              permissions?.some((p) => `/${p}` === item.path.toLowerCase()) && (
                <NavLink to={item.path} key={index} className="link">
                  <div className="icon">{item.icon}</div>
                  <div
                    style={{
                      display: "block",
                      transition: "all 0.5s",
                    }}
                    className="link_text"
                  >
                    {item.name}
                  </div>
                </NavLink>
              )
          )}
        </div>
        <br />
        <div className="">
          {menuItemBottom.map((item, index) =>
            !item ? null : item.isHyperLink ? (
              <a href={item.path} key={index} className="link" target="_blank" rel="noreferrer">
                <MenuChildrenComponent icon={item.icon} name={item.name} />
              </a>
            ) : (
              <NavLink to={item.path} key={index} className="link">
                <MenuChildrenComponent icon={item.icon} name={item.name} />
              </NavLink>
            )
          )}
        </div>
        <div className="">
          {menuItemLogout.map((item, index) => (
            <NavLink to={item.path} key={index} className="link" onClick={handleLogout}>
              <div className="icon">{item.icon}</div>
              <div
                style={{
                  display: "block",
                  transition: "all 0.5s",
                }}
                className="link_text"
              >
                {item.name}
              </div>
            </NavLink>
          ))}
        </div>
      </div>
      <main
        className={"ml-52" + " min-h-screen"}
        style={{
          width: "calc(100% - 13rem)",
          background: "#F2F4FB",
          transition: "all 0.5s",
        }}
      >
        {children}
      </main>
    </div>
  )
}

const MenuChildrenComponent = ({ icon, name }: { icon: JSX.Element; name: string }) => (
  <>
    <div className="icon">{icon}</div>
    <div
      style={{
        display: "block",
        transition: "all 0.5s",
      }}
      className="link_text"
    >
      {name}
    </div>
  </>
)

export default Sidebar
