import { useCallback, useEffect, useState } from "react"
import {
  makeStyles,
  createStyles,
  Typography,
  Grid,
  Button,
  Divider,
  Modal,
  Box,
} from "@material-ui/core"
import { ReactComponent as MarkerIcon } from "./../../../assets/marker.svg"
import { ReactComponent as CalendarIcon } from "./../../../assets/calendar.svg"
import { ReactComponent as CalendarIconB } from "./../../../assets/calendar-b.svg"
import { ReactComponent as BellIcon } from "./../../../assets/bell.svg"
import { ReactComponent as ClockIcon } from "./../../../assets/clock-b.svg"
import { ReactComponent as HourglassIcon } from "./../../../assets/hourglass-b.svg"
import { ReactComponent as WorkordenIcon } from "./../../../assets/menu-orders-b.svg"
import { ReactComponent as MessageIcon } from "./../../../assets/message-text-b.svg"
import { ReactComponent as CloseIcon } from "./../../../assets/close-b.svg"
import { ReactComponent as CloseWIcon } from "./../../../assets/close-w.svg"
import { DetailsFilter } from "./detailsCards/DetailsFilter"
import WorkOrdersLists from "./detailsCards/WorkOrdersList"
import { useNavigate } from "react-router-dom"
import {
  distanceInMiles,
  formatDate,
  formatDateUTC,
  getMinFormat,
  getTime,
  getUrlParam,
} from "../../../utils/Util"
import SiteDetailsCard from "./detailsCards/SiteDetailsCard"
import { Loader } from "../../common/Loader"
import Carousel from "react-material-ui-carousel"
import { useActiveUser } from "../../../providers/AuthProvider"
import { useGraphQLService } from "../../../services/graphqlService"
import { useFetchImages } from "../../../utils/ImageHelper"
import { DateTime } from "luxon"

function SitesDetailsMobile(props: any) {
  const navigate = useNavigate()
  const imageHelper = useFetchImages()
  const { sfId: sfid } = useActiveUser()
  const graphqlService = useGraphQLService()
  const classes = useStyles()
  const [search, searchInput] = useState("")
  const [filter, setFilter] = useState<any>({})
  const [site, setSite] = useState<any>({})
  const [value, setValue] = useState(0)
  const [data, setData] = useState<any>(null)
  const [loading, setLoading] = useState(true)
  const [workOrderId, setWorkOrderId] = useState("")
  const [details, setDetails] = useState<any>({})
  const [loadingDetails, setLoadingDetails] = useState(true)
  const [openImg, setOpenImg] = useState(false)
  const [loadingImges, setLoadingImges] = useState(false)
  const [images, setImages] = useState<any[]>([])
  const [loadingImgesLocation, setLoadingImgesLocations] = useState(false)
  const [imagesLocations, setImagesLocations] = useState<any[]>([])

  const getPartnerData = useCallback(
    (signal?: AbortSignal) => {
      graphqlService
        .getPartnerBySfid({ sfid }, signal)
        .then((response) => {
          setData(response)
        })
        .catch(() => {})
    },
    [graphqlService, sfid]
  )

  const getWorkOrderDetails = useCallback(
    (sfid: any, signal?: AbortSignal) => {
      setLoadingDetails(true)
      setLoadingImges(true)
      graphqlService
        .getWorkLogDetails(
          {
            sfid,
          },
          signal
        )
        .then((response) => {
          setDetails(response)
          setLoadingDetails(false)
          getImageData(response)
        })
        .catch(() => {
          setLoadingDetails(false)
          setLoadingImges(false)
        })
    },
    [graphqlService]
  )

  const getImageData = useCallback(async (response: any) => {
    let totalImg = response?.attachments?.length
    if (!totalImg) {
      setLoadingImges(false)
      setImages([])
      return
    }
    const sfids = response?.attachments.map((a: any) => a.sfid)
    const imagesData = await imageHelper.getImages(sfids)
    setImages(imagesData)
    setLoadingImges(false)
  }, [])

  const getLocationData = useCallback(
    (id: any, signal?: AbortSignal) => {
      setLoading(true)
      setLoadingImgesLocations(true)
      graphqlService
        .getLocationToServiceProvider({ siteId: id, sfid: sfid }, signal)
        .then((response) => {
          setSite(response)
          setLoading(false)
          getImageDataLocations(response)
        })
        .catch(() => {
          setLoading(false)
          setLoadingImgesLocations(false)
        })
    },
    [graphqlService, sfid]
  )

  const getImageDataLocations = useCallback(async (response: any) => {
    let totalImg = response?.attachments?.length
    if (!totalImg || !response?.sfid) {
      setLoadingImges(false)
      setImages([])
      return
    }
    const sfids = response?.attachments.map((a: any) => a.sfid)
    const imagesData = await imageHelper.getImages(sfids, response?.sfid)

    setImagesLocations(imagesData)
    setLoadingImgesLocations(false)
  }, [])

  useEffect(() => {
    const controller = new AbortController()

    const id = getUrlParam("id") as string
    const workorderId = getUrlParam("workorderId") as string
    if (id) {
      getLocationData(id, controller.signal)
      getPartnerData(controller.signal)
    } else {
      navigate("/sites")
    }

    if (workorderId) {
      setWorkOrderId(workorderId)
      getWorkOrderDetails(workorderId, controller.signal)
    } else {
      setWorkOrderId("")
      setDetails({})
    }

    return () => controller.abort()
  }, [
    getLocationData,
    getPartnerData,
    getWorkOrderDetails,
    navigate,
    getUrlParam("workorderId") as string,
  ])

  const distance = () => {
    if (site?.billinglatitude && data?.billinglongitude) {
      let from = {
        lat: site?.billinglatitude,
        lon: site?.billinglongitude,
      }
      let to = {
        lat: data?.billinglatitude,
        lon: data?.billinglongitude,
      }
      return distanceInMiles(from, to)
    }
    return ""
  }

  const openImage = () => {
    setOpenImg(true)
  }

  return (
    <>
      {openImg && (
        <Modal
          open={openImg}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          onClose={() => setOpenImg(false)}
        >
          <Box className={classes.styleImg}>
            <div className={classes.iframeBlockImg}>
              <div className="p-8">
                <Carousel
                  animation={"slide"}
                  autoPlay={false}
                  navButtonsAlwaysVisible={true}
                >
                  {!!images?.length &&
                    images.map((img: any, i: any) => (
                      <div key={i} className="flex flex-col items-center">
                        {loadingImges ? (
                          <Loader />
                        ) : (
                          <img
                            className={classes.carouselImg}
                            alt="photo"
                            src={img}
                          />
                        )}
                      </div>
                    ))}
                </Carousel>
              </div>

              <Button
                variant="text"
                onClick={() => setOpenImg(false)}
                className={classes.imgButtonClose}
              >
                <CloseWIcon />
              </Button>
            </div>
          </Box>
        </Modal>
      )}
      <div className="inline-block bg-gradient-to-b from-case-grad-mid to-case-grad-to-mobile">
        <div className={classes.secondaryTitleMobile}>
          <Typography noWrap className={classes.titleFontMobile}>
            <div
              className={classes.accountName + " flex flex-row items-center"}
            >
              <div className={classes.accountName2 + " w-10/12"}>
                {site?.account_name || "-"}
              </div>
              <div className="mr-3 w-1/12">
                <MarkerIcon />
              </div>
            </div>
          </Typography>
        </div>

        <div>
          <Grid item xs={12}>
            <Grid container className={classes.containerText}>
              <Grid item xs={5} className="flex flex-row items-center">
                <div className="flex flex-row items-center">
                  <CalendarIcon className="mr-3" />
                  <div>
                    <span className={classes.infoFont}>
                      {site?.contract_end_date__c
                        ? formatDateUTC(site?.contract_end_date__c)
                        : "-"}
                    </span>
                    <Typography noWrap className={classes.infoTitle}>
                      Contract End Date
                    </Typography>
                  </div>
                </div>
              </Grid>
              <Grid item xs={5} className="flex flex-row items-center">
                <div className="flex flex-row items-center">
                  <BellIcon className="mr-3" />
                  <div>
                    <span className={classes.infoFont}>
                      {site?.last_serviced_date &&
                      site?.last_serviced_date !== "N/A"
                        ? formatDate(site?.last_serviced_date)
                        : "N/A"}
                    </span>
                    <Typography noWrap className={classes.infoTitle}>
                      Last Serviced
                    </Typography>
                  </div>
                </div>
              </Grid>
              <Grid item xs={2} className="flex flex-row items-center">
                <div className={classes.distance}>
                  <span
                    className={
                      "mt-3 bg-case-grad-from px-2 py-1 rounded text-xs"
                    }
                  >
                    {distance()}
                  </span>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </div>

      <div className="w-full h-8 mb-3">
        <div className="h-6 w-1/2 text-center float-left">
          <Button
            variant="text"
            className={
              classes.buttonTab +
              " w-full " +
              (value === 0 ? classes.selectedTab : "")
            }
            onClick={() => setValue(0)}
          >
            Work Orders
          </Button>
        </div>
        <div className="h-6 w-1/2 text-center float-left">
          <Button
            variant="text"
            className={
              classes.buttonTab +
              " w-full " +
              (value === 1 ? classes.selectedTab : "")
            }
            onClick={() => setValue(1)}
          >
            Site Details
          </Button>
        </div>
      </div>
      <div>
        {value === 0 && (
          <div className="bg-gray min-h-screen">
            {!workOrderId && (
              <>
                <DetailsFilter filter={filter} setFilter={setFilter} />
                {site?.sfid ? (
                  <WorkOrdersLists
                    {...props}
                    search={search}
                    filter={filter}
                    siteId={site?.sfid}
                    isAutoApproved={data?.automatic_work_order_approval}
                  />
                ) : (
                  <Loader />
                )}
              </>
            )}
            {workOrderId && (
              <>
                <div className="m-3">
                  <Grid container>
                    <Grid item xs={12}>
                      <div className="flex flex-row items-center">
                        <Typography className={classes.titleDetails}>
                          Work Orders Details
                        </Typography>

                        <CloseIcon
                          className={classes.closeIcon}
                          onClick={() =>
                            navigate(`/site-details?id=${site?.sfid}`)
                          }
                        />
                      </div>
                    </Grid>

                    <Grid item xs={12} className="flex flex-row items-center">
                      <WorkordenIcon className="ml-3" />
                      <div>
                        <span className={classes.infoFontDetails}>
                          {details?.name}
                        </span>
                        <Typography noWrap className={classes.infoTitleDetails}>
                          Work Order #
                        </Typography>
                      </div>
                    </Grid>

                    <Grid item xs={12} className={classes.divider}>
                      <Divider variant="middle" />
                    </Grid>

                    <Grid item xs={12} className="flex flex-row items-center">
                      <CalendarIconB className="ml-3" />
                      <div>
                        <span className={classes.infoFontDetails}>
                          {details?.service_date
                            ? formatDate(details?.service_date)
                            : "-"}
                        </span>
                        <Typography noWrap className={classes.infoTitleDetails}>
                          Service Date
                        </Typography>
                      </div>
                    </Grid>

                    <Grid item xs={6} className="flex flex-row items-center">
                      <ClockIcon className="ml-3" />
                      <div>
                        <span className={classes.infoFontDetails}>
                          {details?.service_date
                            ? DateTime.fromISO(details?.service_date).toFormat(
                                "t"
                              )
                            : "-"}
                        </span>
                        <Typography noWrap className={classes.infoTitleDetails}>
                          Service Time
                        </Typography>
                      </div>
                    </Grid>

                    <Grid item xs={6} className="flex flex-row items-center">
                      <HourglassIcon className="ml-3" />
                      <div>
                        <span className={classes.infoFontDetails}>
                          {details?.time_taken_minutes
                            ? getMinFormat(details?.time_taken_minutes)
                            : "-"}
                        </span>
                        <Typography noWrap className={classes.infoTitleDetails}>
                          Duration on-site
                        </Typography>
                      </div>
                    </Grid>

                    <Grid item xs={12} className={classes.divider}>
                      <Divider variant="middle" />
                    </Grid>

                    <Grid item xs={12}>
                      <div className={classes.showTitle}>
                        <div>
                          <Typography noWrap className={classes.titleFont}>
                            Services Logged
                          </Typography>
                        </div>
                      </div>
                    </Grid>

                    <Grid item xs={12}>
                      <div className=" m-3">
                        {!!details?.services?.length &&
                          details?.services.map((service: any, index: any) => (
                            <div
                              key={index}
                              className="font-bold flex flex-row items-center"
                            >
                              <div className="w-full">
                                {service?.service_key || "-"}
                                {" / "}
                                {service?.status}
                              </div>
                            </div>
                          ))}
                      </div>
                    </Grid>

                    <Grid item xs={12}>
                      <div className="flex flex-row items-center mt-3">
                        <MessageIcon className="ml-3 mr-3" />
                        <span className={classes.commentsTitle}>Comments:</span>
                      </div>
                    </Grid>
                    <Grid item xs={12}>
                      <div className="flex flex-row items-center mt-3">
                        <span className={classes.comments}>
                          {details?.comments || "-"}
                        </span>
                      </div>
                    </Grid>

                    <Grid item xs={12} className={classes.divider}>
                      <Divider variant="middle" />
                    </Grid>

                    <Grid item xs={12}>
                      <div className={classes.showTitle}>
                        <div>
                          <Typography noWrap className={classes.titleFont}>
                            Photos
                          </Typography>
                        </div>
                      </div>
                    </Grid>

                    <Grid item xs={12}>
                      <div className="m-3">
                        <div className="flex flex-col items-center">
                          {loadingImges ? (
                            <Loader />
                          ) : (
                            <>
                              {!!images?.length &&
                                images.map((img: any, index: any) => (
                                  <div
                                    key={index}
                                    className={classes.imgContainer}
                                  >
                                    <img
                                      className={
                                        classes.image + " cursor-pointer"
                                      }
                                      alt="photo"
                                      src={img}
                                    />
                                  </div>
                                ))}
                            </>
                          )}
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                </div>
              </>
            )}
          </div>
        )}
        {value === 1 && (
          <div>
            {loadingImgesLocation ? (
              <Loader />
            ) : (
              <SiteDetailsCard site={site} images={imagesLocations} />
            )}
          </div>
        )}
      </div>
    </>
  )
}

const useStyles = makeStyles(() =>
  createStyles({
    secondaryTitleMobile: {
      display: "flex",
      justifyContent: "space-between",
      maxWidth: "100vw",
    },
    titleFontMobile: {
      fontSize: "28px",
      fontWeight: "bold",
      lineHeight: "113%",
      color: "white",
      letterSpacing: "-1px",
      marginLeft: "20px",
      marginTop: "21px",
    },
    secondaryTitleFont: {
      fontSize: "9px",
      lineHeight: "113%",
      color: "white",
      letterSpacing: "-1px",
      marginLeft: "20px",
    },
    infoFont: {
      color: "white",
      fontWeight: "bold",
      fontSize: 20,
    },
    infoTitle: {
      fontSize: "13px",
      lineHeight: "113%",
      color: "white",
      letterSpacing: "-1px",
      marginBottom: "10px",
    },
    accountName: {
      width: "100vw",
    },
    accountName2: {
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
    containerText: {
      width: "auto",
      margin: "20px",
    },
    buttonTab: {
      color: "#0F2150",
      fontWeight: "bold",
      backgroundColor: "white",
    },
    selectedTab: {
      borderBottom: "2px solid #0F2150",
    },
    distance: {
      color: "white",
    },
    titleDetails: {
      fontSize: "22px",
      fontWeight: 800,
      lineHeight: "113%",
      color: "#000",
      letterSpacing: "-1px",
      marginBottom: "20px",
    },
    infoFontDetails: {
      color: "black",
      fontWeight: "bold",
      fontSize: 20,
      marginLeft: "20px",
    },

    styleImg: {},
    iframeBlock: {
      height: "93%",
      padding: "5px",
    },
    image: {
      width: "100%",
      height: "auto",
    },
    imgContainer: {
      position: "relative",
      width: "100%",
      margin: "10px 0px",
    },
    imgButton: {
      background: "white",
      top: 0,
      right: 0,
      border: "none",
      cursor: "pointer",
      position: "absolute",
      textAlign: "center",
      borderRadius: "5px",
      height: "25px",
      minWidth: "25px",
    },
    commentsTitle: {
      color: "#0F2150",
      fontWeight: "bold",
      fontSize: 15,
      marginLeft: "0px",
    },
    comments: {
      fontSize: 15,
      marginLeft: "20px",
    },
    infoTitleDetails: {
      fontSize: "13px",
      lineHeight: "113%",
      color: "gray",
      letterSpacing: "-1px",
      marginBottom: "10px",
      marginLeft: "20px",
    },
    showTitle: {
      width: "auto",
      marginTop: "8px",
      marginLeft: "10px",
    },
    titleFont: {
      fontSize: "22px",
      fontWeight: 800,
      lineHeight: "113%",
      color: "#000",
      letterSpacing: "-1px",
      marginBottom: "20px",
    },
    divider: {
      marginTop: "10px",
      marginBottom: "10px",
    },
    greenStatus: {
      backgroundColor: "#d7f5e3",
      color: "#4e996c",
      borderRadius: "20px",
      padding: "5px 10px",
    },
    redStatus: {
      backgroundColor: "#fed5d5",
      color: "#ff3b5f",
      borderRadius: "20px",
      padding: "5px 10px",
    },
    yellowStatus: {
      backgroundColor: "#ffd978",
      color: "#665730",
      borderRadius: "20px",
      padding: "5px 10px",
    },
    closeIcon: {
      right: "0",
      position: "absolute",
      margin: "-7px 10px 0px 0px",
    },
    imgButtonClose: {
      background: "red",
      top: 0,
      right: 0,
      border: "none",
      cursor: "pointer",
      position: "absolute",
      textAlign: "center",
      borderRadius: "5px",
      height: "25px",
      minWidth: "25px",
    },
    iframeBlockImg: {
      width: "100%",
      margin: "5px",
      backgroundColor: "white",
      position: "absolute",
    },
    carouselImg: {
      maxHeight: "85vh",
    },
  })
)

export default SitesDetailsMobile
