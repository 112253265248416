import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { Loader } from "../../../common/Loader"
import {
  makeStyles,
  createStyles,
  Container,
  Grid,
  Typography,
  Button,
} from "@material-ui/core"
import { ReactComponent as DownIcon } from "./../../../../assets/down.svg"
import { ReactComponent as SnowIcon } from "./../../../../assets/cloud-snow-lb.svg"
import { ReactComponent as TreeIcon } from "./../../../../assets/tree-g.svg"
import { ReactComponent as SweepingIcon } from "./../../../../assets/sweeping.svg"
import { ReactComponent as JanitoralIcon } from "./../../../../assets/janitoral-b.svg"
import Divider from "@mui/material/Divider"
import { useActiveUser } from "../../../../providers/AuthProvider"
import { useGraphQLService } from "../../../../services/graphqlService"

export const SitesLists = ({ setWOs }: any) => {
  const classes = useStyles()
  const navigate = useNavigate()
  const { sfId: sfid } = useActiveUser()
  const graphqlService = useGraphQLService()

  const [data, setData] = useState<any[]>([])
  const [sort, setSort] = useState({ field: "status", order: "ASC" })
  const [loading, setLoading] = useState(true)
  const [loadingMore, setLoadingMore] = useState(true)
  const [error, setError] = useState("")
  const [total, setTotal] = useState(0)
  const [page, setPage] = useState(1)
  const [perPage, setPerPage] = useState(10)

  useEffect(() => {
    const controller = new AbortController()

    if (page === 1) {
      setLoading(true)
    } else {
      setLoadingMore(true)
    }
    graphqlService
      .getSites(
        {
          sfid,
          limit: perPage,
          offset: (page - 1) * perPage,
          sort: sort.field,
          order: sort.order,
        },
        controller.signal
      )
      .then((response) => {
        setData((d) => [...d, ...response.sites])
        setTotal(response.pagination.found_rows)
        setWOs(response?.total_worklogs)
        setLoading(false)
        setLoadingMore(false)
      })
      .catch(() => {
        setLoading(false)
        setLoadingMore(false)
      })

    return () => controller.abort()
  }, [graphqlService, page, perPage, setWOs, sfid, sort])

  const showDetails = (site: any) => {
    navigate(`/site-details?id=${site.sfid}`)
  }

  if (error) {
    return <p>ERROR: {error}</p>
  } else {
    return (
      <>
        {loading && (
          <div className={classes.listContainer}>
            <Loader />
          </div>
        )}

        {!loading && !data.length && (
          <div className={classes.listContainer}>
            <div>EMPTY</div>
          </div>
        )}

        {!loading && !!data.length && (
          <div>
            <Container className={classes.container}>
              {data.map((row: any, i: any) => (
                <Grid
                  key={`${i}_${row.sfid}`}
                  container
                  onClick={() => showDetails(row)}
                  style={{ cursor: "pointer" }}
                >
                  <Grid item xs={12}>
                    <div className={classes.grid}>
                      <div className={classes.secondaryTitle}>
                        <Typography noWrap className={classes.cardTitle}>
                          {row?.account_name}
                        </Typography>
                      </div>

                      <Divider variant="middle" className={classes.divider} />

                      <div className={classes.data}>
                        <div>
                          {row?.billingstreet} {row?.billingcity},{" "}
                          {row?.billingstate}
                        </div>
                        <div>
                          Completed Work Orders:{" "}
                          <span className={classes.cardDetails}>
                            {row.work_orders}
                          </span>
                        </div>
                        <div className="flex flex-row items-center">
                          <span>Service: </span>
                          <span className={classes.cardDetails}>
                            {row.service_line && (
                              <div className="flex flex-row items-center h-1">
                                {row.service_line.indexOf("Snow") >= 0 ? (
                                  <SnowIcon className="w-7 ml-2" />
                                ) : (
                                  ""
                                )}
                                {row.service_line.indexOf("Land") >= 0 ? (
                                  <TreeIcon className="w-7 ml-2" />
                                ) : (
                                  ""
                                )}
                                {row.service_line.indexOf("Sweeping") >= 0 ? (
                                  <SweepingIcon className="w-7 ml-2" />
                                ) : (
                                  ""
                                )}
                                {row.service_line.indexOf("Janitorial") >= 0 ? (
                                  <JanitoralIcon className="w-7 ml-2" />
                                ) : (
                                  ""
                                )}
                              </div>
                            )}
                          </span>
                        </div>
                      </div>
                    </div>
                  </Grid>
                </Grid>
              ))}
              {loadingMore ? (
                <Loader />
              ) : (
                <Grid container>
                  <Grid item xs={12}>
                    <div className="w-full flex flex-col align-items">
                      <Button
                        variant="text"
                        size="small"
                        onClick={() => setPage(page + 1)}
                      >
                        <DownIcon />
                      </Button>
                    </div>
                  </Grid>
                </Grid>
              )}
            </Container>
          </div>
        )}
      </>
    )
  }
}
const useStyles = makeStyles(() =>
  createStyles({
    listContainer: {
      width: "auto",
      padding: "0px",
    },
    location: {
      fontWeight: "bold",
      color: "#1695CB",
    },
    cardTitle: {
      fontWeight: 600,
      lineHeight: "113%",
      color: "#232F64",
      letterSpacing: "-1px",
      marginLeft: "5px",
      marginTop: "21px",
      fontSize: "20px",
    },
    grid: {
      border: "1px solid #E0E0E0",
      borderRadius: "5px",
      marginTop: "10px",
      background: "#FFFFFF",
    },
    container: {
      maxWidth: "100%",
      padding: "0px",
    },
    secondaryTitle: {
      display: "flex",
      justifyContent: "space-between",
      paddingBottom: "15px",
      paddingLeft: "15px",
      paddingRight: "15px",
    },
    data: {
      padding: "15px",
    },
    cardDetails: {
      fontWeight: "bold",
      color: "#0F2150",
    },
    active: {
      backgroundColor: "#d7f5e3",
      color: "#4e996c",
      borderRadius: "20px",
      padding: "2px 11px",
      height: "30px",
      marginRight: "5px",
      marginTop: "15px",
      marginLeft: "10px",
    },
    inactive: {
      backgroundColor: "#fed5d5",
      color: "#ff3b5f",
      borderRadius: "20px",
      padding: "2px 11px",
      height: "30px",
      marginRight: "5px",
      marginTop: "15px",
      marginLeft: "10px",
    },
    divider: {
      marginTop: "10px",
      marginBottom: "10px",
    },
  })
)

export default SitesLists
