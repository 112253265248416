import { useCallback, useEffect, useState } from "react"
import {
  makeStyles,
  createStyles,
  Container,
  Grid,
  Button,
} from "@material-ui/core"
import { useNavigate } from "react-router-dom"
import { ReactComponent as RightIcon } from "./../../../assets/right.svg"
import { ReactComponent as BellIcon } from "./../../../assets/bell-b.svg"
import { ReactComponent as ClipboardIcon } from "./../../../assets/clipboard-check-b.svg"
import { ReactComponent as CalendarIcon } from "./../../../assets/calendar-b.svg"
import { distanceInMiles, formatDate, getUrlParam } from "../../../utils/Util"
import Typography from "@mui/material/Typography"
import Box from "@mui/material/Box"
import WorkOrdersList from "./detailsCards/WorkOrdersList"
import { DetailsFilter } from "./detailsCards/DetailsFilter"
import { Loader } from "../../common/Loader"
import Map from "../../common/googleMap"
import SiteDetailsCard from "./detailsCards/SiteDetailsCard"
import { useActiveUser } from "../../../providers/AuthProvider"
import { useGraphQLService } from "../../../services/graphqlService"
import { useFetchImages } from "../../../utils/ImageHelper"
import useI18n from "../../../hooks/useI18n"

function SiteDetailsDesktop(props: any) {
  const navigate = useNavigate()
  const imageHelper = useFetchImages()
  const classes = useStyles()
  const { i18n } = useI18n();
  const graphqlService = useGraphQLService()
  const [search, searchInput] = useState("")
  const [filter, setFilter] = useState<any>({})
  const [site, setSite] = useState<any>({})
  const [value, setValue] = useState(0)
  const [loading, setLoading] = useState(true)
  const { sfId: sfid } = useActiveUser()
  const [data, setData] = useState<any>(null)
  const [loadingPartner, setLoadingPartner] = useState(true)
  const [loadingImges, setLoadingImges] = useState(false)
  const [images, setImages] = useState<any[]>([])

  const getPartnerData = useCallback(
    (signal?: AbortSignal) => {
      setLoadingPartner(true)
      graphqlService
        .getPartnerBySfid({ sfid }, signal)
        .then((response) => {
          setLoadingPartner(false)
          setData(response)
        })
        .catch(() => {})
    },
    [graphqlService, sfid]
  )

  const getLocationData = useCallback(
    (id: any, signal?: AbortSignal) => {
      setLoading(true)
      setLoadingImges(true)
      graphqlService
        .getLocationToServiceProvider({ siteId: id, sfid: sfid }, signal)
        .then((response) => {
          setSite(response)
          setLoading(false)
          getImageData(response)
        })
        .catch(() => {
          setLoading(false)
          setLoadingImges(false)
        })
    },
    [graphqlService, sfid]
  )

  useEffect(() => {
    const controller = new AbortController()

    const id = getUrlParam("id") as string
    if (id) {
      getLocationData(id, controller.signal)
      getPartnerData(controller.signal)
    } else {
      navigate("/sites")
    }

    return () => controller.abort()
  }, [getLocationData, getPartnerData, navigate])

  const distance = () => {
    if (site?.billinglatitude && data?.billinglongitude) {
      let from = {
        lat: site?.billinglatitude,
        lon: site?.billinglongitude,
      }
      let to = {
        lat: data?.billinglatitude,
        lon: data?.billinglongitude,
      }
      return distanceInMiles(from, to)
    }
    return ""
  }

  const getImageData = useCallback(async (response: any) => {
    let totalImg = response?.attachments?.length
    if (!totalImg || !response?.sfid) {
      setLoadingImges(false)
      setImages([])
      return
    }
    const sfids = response?.attachments.map((a: any) => a.sfid)
    const imagesData = await imageHelper.getImages(sfids, response?.sfid)
    setImages(imagesData)
    setLoadingImges(false)
  }, [])

  if (loading) {
    return <Loader />
  } else {
    return (
      <>
        <div>
          <Container className={classes.container}>
            <Grid container>
              <Grid item xs={12}>
                <Grid container>
                  <Grid item xs={6}>
                    <Grid container>
                      <Grid item xs={12}>
                        <div className={classes.showTitle}>
                          <div className="mb-8">
                            <Typography
                              noWrap
                              className="flex flex-row items-center"
                            >
                              <span
                                className="cursor-pointer text-breadcrumb-blue font-bold"
                                onClick={() => navigate("/")}
                              >
                                {i18n.t("breadcrumbs.home")}
                              </span>
                              <RightIcon className="w-3.5 mr-3 ml-3" />
                              <span
                                className="cursor-pointer text-breadcrumb-blue font-bold"
                                onClick={() => navigate("/sites")}
                              >
                                {i18n.t("breadcrumbs.siteListing")}
                              </span>
                              <RightIcon className="w-3.5 mr-3 ml-3" />
                              <span className="font-bold">
                                {i18n.t("breadcrumbs.siteDetails")}
                              </span>
                            </Typography>
                          </div>
                          <div>
                            <Typography className={classes.titleFont}>
                              {site?.account_name || "-"}
                            </Typography>
                          </div>
                        </div>
                      </Grid>

                      <Grid item xs={12}>
                        <Grid container>
                          <Grid
                            item
                            xs={4}
                            className="flex flex-row items-center"
                          >
                            <CalendarIcon className="mr-3" />
                            <div>
                              <span className={classes.infoFont}>
                                {site?.contract_end_date__c
                                  ? formatDate(site?.contract_end_date__c)
                                  : "-"}
                              </span>
                              <Typography noWrap className={classes.infoTitle}>
                                {i18n.t("bids.details.contractEndDate")}
                              </Typography>
                            </div>
                          </Grid>
                          <Grid
                            item
                            xs={4}
                            className="flex flex-row items-center"
                          >
                            <ClipboardIcon className="mr-3" />
                            <div>
                              <span className={classes.infoFont}>
                                {site?.work_orders}
                              </span>
                              <Typography noWrap className={classes.infoTitle}>
                                {i18n.t("sites.wosCompleted")}
                              </Typography>
                            </div>
                          </Grid>
                          <Grid
                            item
                            xs={4}
                            className="flex flex-row items-center"
                          >
                            <BellIcon className="mr-3" />
                            <div>
                              <span className={classes.infoFont}>
                                {site?.last_serviced_date &&
                                site?.last_serviced_date !== "N/A"
                                  ? formatDate(site?.last_serviced_date)
                                  : "N/A"}
                              </span>
                              <Typography noWrap className={classes.infoTitle}>
                                {i18n.t("sites.details.lastServiced")}
                              </Typography>
                            </div>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={6} className="w-full">
                    <div className={classes.gridMap}>
                      {!loading && !loadingPartner && (
                        <div className="relative">
                          <Map
                            center={{
                              lat: site?.billinglatitude,
                              lng: site?.billinglongitude,
                            }}
                            principalMarkers={[
                              {
                                id: 0,
                                name: data?.name,
                                lat: data?.billinglatitude,
                                lng: data?.billinglongitude,
                              },
                            ]}
                            siteMarkers={
                              site?.billinglatitude
                                ? [
                                    {
                                      id: site?.sfid,
                                      name: site?.account_name,
                                      lat: site?.billinglatitude,
                                      lng: site?.billinglongitude,
                                    },
                                  ]
                                : []
                            }
                            pendingBidMarkers={[]}
                            openBidMarkers={[]}
                            isLoaded={true}
                            maxHeight={"250px"}
                            setMarker={true}
                            defaultMarker={site?.sfid || 0}
                            zoom={10}
                          />
                          <div className={classes.distance}>
                            <span
                              className={
                                classes.spanDistance +
                                " ml-52 mt-3 bg-case-grad-from px-2 py-1 rounded text-xs"
                              }
                            >
                              {distance()}
                            </span>
                          </div>
                        </div>
                      )}
                    </div>
                  </Grid>
                </Grid>

                <Grid container>
                  <Grid item xs={12}>
                    <div className="w-full">
                      <Box sx={{ width: "100%" }}>
                        <Box
                          sx={{
                            borderColor: "divider",
                          }}
                        >
                          <div className="w-full h-8 mb-3">
                            <div className="mt-2">
                              <div className="h-6 w-1/2 text-center float-left">
                                <Button
                                  variant="text"
                                  className={
                                    classes.buttonTab +
                                    " w-full " +
                                    (value === 0 ? classes.selectedTab : "")
                                  }
                                  onClick={() => setValue(0)}
                                >
                                  {i18n.t("sites.details.workOrders")}
                                </Button>
                              </div>
                              <div className="h-6 w-1/2 text-center float-left">
                                <Button
                                  variant="text"
                                  className={
                                    classes.buttonTab +
                                    " w-full " +
                                    (value === 1 ? classes.selectedTab : "")
                                  }
                                  onClick={() => setValue(1)}
                                >
                                  {i18n.t("sites.siteDetails")}
                                </Button>
                              </div>
                            </div>
                          </div>
                        </Box>
                        {value === 0 && site?.sfid && (
                          <div className={classes.grid}>
                            <DetailsFilter
                              filter={filter}
                              setFilter={setFilter}
                            />
                            <WorkOrdersList
                              {...props}
                              search={search}
                              filter={filter}
                              siteId={site?.sfid}
                              isAutoApproved={data?.automatic_work_order_approval}
                            />
                          </div>
                        )}
                        {value === 1 && (
                          <>
                            {loadingImges ? (
                              <Loader />
                            ) : (
                              <SiteDetailsCard site={site} images={images} />
                            )}
                          </>
                        )}
                      </Box>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Container>
        </div>
      </>
    )
  }
}

const useStyles = makeStyles(() =>
  createStyles({
    showTitle: {
      width: "auto",
      marginTop: "10px",
      marginLeft: "10px",
    },
    titleFont: {
      fontSize: "44px!important",
      "font-weight": "800!important",
      lineHeight: "113%!important",
      color: "#000!important",
      letterSpacing: "-1px!important",
      marginBottom: "20px!important",
    },
    secondaryTitleFont: {
      fontSize: "13px",
      lineHeight: "113%",
      color: "gray",
      letterSpacing: "-1px",
      marginTop: "10px",
    },
    cardTitle: {
      fontWeight: 800,
      lineHeight: "113%",
      color: "#232F64",
      letterSpacing: "-1px",
      marginLeft: "40px",
      marginTop: "21px",
    },
    grid: {
      border: "1px solid #E0E0E0",
      borderRadius: "5px",
      margin: "5px",
      background: "#FFFFFF",
      padding: "15px",
    },
    gridMap: {
      border: "1px solid #E0E0E0",
      borderRadius: "5px",
      margin: "5px",
      background: "#FFFFFF",
    },
    container: {
      maxWidth: "100%",
    },
    infoFont: {
      color: "#0F2150",
      fontWeight: "bold",
      fontSize: 20,
    },
    infoTitle: {
      fontSize: "13px",
      lineHeight: "113%",
      color: "gray",
      letterSpacing: "-1px",
      marginBottom: "10px",
    },
    direction: {
      marginTop: "0.7rem",
      marginBottom: "0.7rem",
    },
    distance: {
      color: "white",
    },
    tab: {
      marginTop: "30px",
    },
    buttonTab: {
      color: "#0F2150",
      fontWeight: "bold",
      backgroundColor: "white",
    },
    selectedTab: {
      borderBottom: "2px solid #0F2150",
    },
    spanDistance: {
      top: 0,
      left: 0,
      border: "none",
      position: "absolute",
    },
  })
)

export default SiteDetailsDesktop
