import { useCallback, useContext, useEffect, useState } from "react"

import { CircularProgress, Container, Grid, MenuItem, Modal, Snackbar } from "@material-ui/core"
import { useNavigate } from "react-router-dom"
import { useDebounce } from "use-debounce"
import InputAdornment from "@mui/material/InputAdornment"
import AcUnitOutlinedIcon from "@mui/icons-material/AcUnitOutlined"
import DoneAllOutlinedIcon from "@mui/icons-material/DoneAllOutlined"
import LocationOnIcon from "@mui/icons-material/LocationOn"
import AcUnitIcon from "@mui/icons-material/AcUnit"
import Select from "@mui/material/Select"
import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"
import Button from "@mui/material/Button"
import Autocomplete from "@mui/material/Autocomplete"
import TextField from "@mui/material/TextField"
import Alert from "@mui/material/Alert"

import "./ValidationResultsPage.css"
import { useAdminStyles } from "../../../Admin/styles"
import { useGraphQLService } from "../../../../services/graphqlService"
import { AccountResponse, SnowWorklog, SnowWorklogsResponse, SortField, Validation, ValidationTypes, ValidationsItem } from "../../interfaces"
import { ValidationContext } from "../../../../providers/ValidationProvider"
import { ReactComponent as TreeIcon } from "../../../../assets/tree-g.svg"
import { Loader } from "../../../common/Loader"
import SnowValidationTable from "./components/SnowValidationTable"
import LandChartStatusIconComponent, { LandStatusColor } from "./components/LandChartStatusIconComponent"
import LandMainPageComponent from "./components/LandMainPageComponent"
import LandDetailsComponent from "../SelectedValidationPage/components/LandDetailsComponent"
import LandFilteringComponent from "./components/LandFilteringComponent"
import PaginationComponent from "../../components/paginationComponent"
import SnowValidationWithoutWeatherTable from "./components/SnowValidationWithoutWeatherTable"

const ValidationResults = () => {
  const { validationState, setValidation } = useContext(ValidationContext)
  const [loading, setLoading] = useState(false)
  const [isAutoApproving, setAutoApproving] = useState(false)
  const [inputLoading, setInputLoading] = useState(false)
  const [siteList, setSiteList] = useState(validationState?.siteList ?? [])
  const [siteInputText, setSiteInputText] = useState("")
  const [value] = useDebounce(siteInputText, 1000)
  const [currentTab, setCurrentTab] = useState(0)

  const classes = useAdminStyles()
  const navigate = useNavigate()
  const {
    getValidations,
    getSnowWorklogs,
    getAccounts,
    getWorkLogDetails,
    updateWorkOrderService,
    updateWorkOrderValidation,
    updateWorklogReviewStatus,
  } = useGraphQLService()

  const getWorkOrdersCountByStatus = useCallback((status: string, validationItems: ValidationsItem[]) => {
    const statuses = status === "Approved" ? ["Approved", "Cancelled", "Edit", "Not Applicable"] : ["Pending", "Review", "On Hold"]

    const total = validationItems.reduce((t, item) => t + item?.worklogs?.filter((w) => statuses.includes(w?.approvalStatus))?.length ?? 0, 0)

    return total
  }, [])

  const fetchValidationsPerPage = useCallback(
    async (
      pageToken: string,
      sort?: { by: SortField; order: "ASC" | "DESC" },
      filteringBy?: "all" | "ReviewPending" | "ReviewCompleted",
      siteId?: string,
      signal?: AbortSignal
    ): Promise<
      | {
          items: Validation[]
          totalCount: number
          nextPageToken?: string
        }
      | SnowWorklogsResponse
      | undefined
    > => {
      try {
        let filter = filteringBy ? filteringBy : validationState?.currentFilter?.status
        filter = currentTab === 1 ? (filter === "ReviewCompleted" ? "Approved" : filter) : filter

        if (currentTab === 0) {
          const result = await getValidations(
            validationState?.user?.sfid ?? "",
            validationState?.startDate ?? "",
            validationState?.endDate ?? "",
            filter && filter !== "all" ? filter : "",
            sort
              ? `${sort.by}:${sort.order.toLowerCase()}`
              : validationState?.snowSort
              ? `${validationState?.snowSort.by}:${validationState?.snowSort.order.toLowerCase()}`
              : "status:desc",
            validationState?.perPageWithStorm,
            siteId ?? validationState?.currentFilter?.siteId,
            pageToken,
            signal
          )

          return result
        } else {
          const result = await getSnowWorklogs(
            {
              amId: validationState?.user?.sfid ?? "",
              locationId: siteId ?? validationState?.currentFilter?.siteId ?? "",
              startDate: validationState?.startDate ?? "",
              endDate: validationState?.endDate ?? "",
              approvalStatus: filter && filter !== "all" ? (filter === "ReviewPending" ? "Pending" : "Approved") : "",
              sort: sort ? sort.by : validationState?.workOrderSort ? validationState?.workOrderSort.by : "siteName",
              order: sort ? sort.order : validationState?.workOrderSort ? validationState?.workOrderSort.order : "desc",
              limit: validationState?.perPageWithoutStorm,
              nextPageToken: pageToken,
            },
            signal
          )

          return result
        }
      } catch (error) {
        console.log(error, "ERROR GETTING VALIDATIONS PER PAGE")

        return
      }
    },
    [
      validationState?.currentFilter?.status,
      validationState?.currentFilter?.siteId,
      validationState?.user?.sfid,
      validationState?.startDate,
      validationState?.endDate,
      validationState?.snowSort,
      validationState?.perPageWithStorm,
      validationState?.workOrderSort,
      validationState?.perPageWithoutStorm,
      currentTab,
      getValidations,
      getSnowWorklogs,
    ]
  )

  const handleValidationPageChange = useCallback(
    async (
      direction: "next" | "prev" | "" = "",
      reset: boolean = false,
      sort?: { by: SortField; order: "ASC" | "DESC" },
      filteringBy?: "all" | "ReviewPending" | "ReviewCompleted",
      siteId?: string,
      signal?: AbortSignal
    ): Promise<any> => {
      try {
        if (validationState?.validationType === ValidationTypes.Land) return

        const pageWithStorm = validationState?.pageWithStorm ?? 1

        setLoading(true)

        let pageToken = ""

        if (!reset) {
          if (direction === "next") {
            pageToken = validationState.snowPageTokenList?.[pageWithStorm - 1] ?? ""
          } else if (direction === "prev") {
            pageToken = validationState.snowPageTokenList?.[pageWithStorm - 3] ?? ""
          } else {
            pageToken = validationState.snowPageTokenList?.[pageWithStorm - 2] ?? ""
          }
        }

        let response = (await fetchValidationsPerPage(pageToken ?? "", sort, filteringBy, siteId, signal)) as {
          items: Validation[]
          totalCount: number
          nextPageToken?: string
        }

        if (!response) return

        let result: Validation[] = []
        let pendingValidations = [] as { stormId: string; name: string; index: number }[]

        result = response?.items?.map((item, index) => {
          const underOverCounter = getUnderOverCounterLabel(item.validations)

          item.underover = `${underOverCounter.under} / ${underOverCounter.over}`

          if (
            getWorkOrdersCountByStatus("Pending", item.validations) === 0 &&
            validationState?.currentFilter?.status === "ReviewPending" &&
            item.status !== "ReviewCompleted" &&
            item.status !== "Completed" &&
            item.status !== "ReviewComplete"
          ) {
            pendingValidations.push({ stormId: item.stormId, name: item.name, index })
          }

          return item
        })

        if (pendingValidations.length > 0) {
          let currentStormId = ""

          try {
            setAutoApproving(true)

            for (let pendingIndex = 0; pendingIndex < pendingValidations.length; pendingIndex++) {
              const { stormId, name, index } = pendingValidations[pendingIndex]
              currentStormId = stormId

              const resp = await updateWorkOrderValidation(
                {
                  action: "ReviewCompleted",
                  validationName: name,
                },
                signal
              )

              result[index].status = "ReviewCompleted"

              if (resp !== true) {
                throw new Error("Error updating validation")
              }
            }

            setTimeout(() => {
              setAutoApproving(false)
              handleValidationPageChange(direction, reset, sort, filteringBy, siteId, signal)
            }, 500)

            return
          } catch (error) {
            alert(`An error occurs trying to update the following Storm ID: ${currentStormId}. For more details, please take a look at this storm.`)
          }
        }

        let pageTokenList = validationState.snowPageTokenList ?? []
        let totalWithStorm = validationState?.totalWithStorm
        let newPage = reset
          ? 1
          : direction === "next"
          ? pageWithStorm + 1
          : direction.length
          ? pageWithStorm - 1
          : validationState?.pageWithStorm ?? 1

        if (totalWithStorm === 0 || (response.totalCount !== null && response.totalCount !== totalWithStorm)) {
          totalWithStorm = response.totalCount

          if (newPage > (Math.ceil(totalWithStorm / (validationState?.perPageWithStorm ?? 10)) || 1)) {
            newPage = Math.ceil(totalWithStorm / (validationState?.perPageWithStorm ?? 10))
          }
        }

        if (direction === "prev") {
          pageTokenList = pageTokenList.slice(0, pageTokenList.length - 1)
        } else if (direction === "next") {
          pageTokenList = [...pageTokenList, response.nextPageToken]
        } else if (pageTokenList.length === 0 && newPage === 1) {
          pageTokenList = [response.nextPageToken]
        } else {
          pageTokenList = [...pageTokenList]
        }

        setValidation({
          ...validationState,
          validations: result,
          pageWithStorm: newPage,
          snowSort: sort ?? validationState?.snowSort,
          snowPageTokenList: reset ? [(response as any).nextPageToken || ""] : pageTokenList || [],
          currentFilter: {
            ...validationState.currentFilter,
            siteId: siteId ?? validationState?.currentFilter?.siteId,
            status: filteringBy ?? validationState?.currentFilter?.status,
          },
          totalWithStorm,
        })

        return result
      } catch (error) {
        console.log(error)
        return null
      } finally {
        setLoading(false)
      }
    },
    [validationState, fetchValidationsPerPage, setValidation, getWorkOrdersCountByStatus, updateWorkOrderValidation]
  )

  const handleSnowWorklogsPageChange = useCallback(
    async (
      direction: "next" | "prev" | "" = "",
      reset: boolean = false,
      sort?: { by: SortField; order: "ASC" | "DESC" },
      filteringBy?: "all" | "ReviewPending" | "ReviewCompleted",
      siteId?: string,
      signal?: AbortSignal
    ) => {
      try {
        if (validationState?.validationType === ValidationTypes.Land) return

        const pageWithoutStorm = validationState?.pageWithoutStorm ?? 1

        setLoading(true)

        let pageToken = ""

        if (!reset) {
          if (direction === "next") {
            pageToken = validationState.workOrderPageTokenList?.[pageWithoutStorm - 1] ?? ""
          } else if (direction === "prev") {
            pageToken = validationState.workOrderPageTokenList?.[pageWithoutStorm - 3] ?? ""
          } else {
            pageToken = validationState.workOrderPageTokenList?.[pageWithoutStorm - 2] ?? ""
          }
        }

        const response = (await fetchValidationsPerPage(pageToken ?? "", sort, filteringBy, siteId, signal)) as SnowWorklogsResponse

        if (!response) return

        let completedWOs = [] as { work_log_id: string; index: number; name: string | null }[]

        for (let index = 0; index < response.worklogs.length; index++) {
          const worklog = response.worklogs[index]

          if (!worklog.isReviewed) {
            const details = await getWorkLogDetails({ sfid: worklog.workLogId }, undefined, true)

            if (
              details?.services &&
              details.services.length > 0 &&
              !details.services.find((service: any) => service.approval_status && service.approval_status === "Pending")
            ) {
              completedWOs.push({ work_log_id: worklog.workLogId, index, name: worklog.workOrderName })
            }
          }
        }

        if (completedWOs.length > 0) {
          let currentWOName: any = null

          try {
            setAutoApproving(true)

            for (let completedIndex = 0; completedIndex < completedWOs.length; completedIndex++) {
              const { work_log_id, index, name } = completedWOs[completedIndex]
              currentWOName = name

              const resp = await updateWorklogReviewStatus(
                {
                  work_log_id,
                  isReviewed: true,
                },
                signal
              )

              response.worklogs[index].isReviewed = resp

              if (resp !== true) {
                throw new Error("Error updating WO review status")
              }
            }

            setTimeout(() => {
              setAutoApproving(false)
              // handleSnowWorklogsPageChange(direction, reset, sort, filteringBy, siteId, signal)
            }, 500)

            return
          } catch (error) {
            alert(
              `An error occurs trying to update the following WO review status: ${currentWOName}. For more details, please take a look at this WO.`
            )
          }
        }

        let pageTokenList = validationState.workOrderPageTokenList ?? []
        let totalWithoutStorm = validationState?.totalWithoutStorm
        const newPage = reset
          ? 1
          : direction === "next"
          ? pageWithoutStorm + 1
          : direction.length
          ? pageWithoutStorm - 1
          : validationState?.pageWithoutStorm

        if (totalWithoutStorm === 0 || (response.totalRows && response.totalRows !== null && +response.totalRows !== totalWithoutStorm)) {
          totalWithoutStorm = +response.totalRows
        }

        if (direction === "prev") {
          pageTokenList = pageTokenList.slice(0, pageTokenList.length - 1)
        } else if (direction === "next") {
          pageTokenList = [...pageTokenList, response.nextPageToken]
        } else if (pageTokenList.length === 0 && newPage === 1) {
          pageTokenList = [response.nextPageToken]
        } else {
          pageTokenList = [...pageTokenList]
        }

        setValidation({
          ...validationState,
          workOrders: response.worklogs,
          workOrderSort: sort ?? validationState?.workOrderSort,
          pageWithoutStorm: newPage,
          workOrderPageTokenList: reset ? [(response as any).nextPageToken || ""] : pageTokenList || [],
          currentFilter: {
            ...validationState.currentFilter,
            siteId: siteId ?? validationState?.currentFilter?.siteId,
            status: filteringBy ?? validationState?.currentFilter?.status,
          },
          totalWithoutStorm,
        })

        return response
      } catch (error) {
        console.log(error)
        return null
      } finally {
        setLoading(false)
      }
    },
    [fetchValidationsPerPage, getWorkLogDetails, setValidation, updateWorklogReviewStatus, validationState]
  )

  const handleStatusChange = useCallback(
    async (status: "Approved" | "Cancelled" | "Edit", wlItem: SnowWorklog) => {
      try {
        setLoading(true)

        if (status !== "Edit") {
          const response: any = await getWorkLogDetails({ sfid: wlItem.workLogId }, undefined, true)

          if (response) {
            for (let i = 0; i < response.services.length; i++) {
              const service = response.services[i]

              await updateWorkOrderService({
                approval_status: status,
                service_id: service?.external_id ?? "",
              })
            }

            if (!wlItem.isReviewed && response?.services && response.services.length > 0) {
              setAutoApproving(true)

              await updateWorklogReviewStatus({
                work_log_id: wlItem.workLogId,
                isReviewed: true,
              })
            }
          }
        }
      } catch (error) {
        alert("An error occurs trying to update WOS Approval Status, please try again.")
      } finally {
        setLoading(false)
        setAutoApproving(false)
      }
    },
    [getWorkLogDetails, updateWorkOrderService, updateWorklogReviewStatus]
  )

  const handleServiceStatusChange = useCallback(
    async (status: "Cancelled" | "Approved", serviceItem: any, row: SnowWorklog) => {
      setLoading(true)

      try {
        const resp = await updateWorkOrderService({
          approval_status: status,
          service_id: serviceItem?.external_id ?? "",
        })

        if (resp) {
          await handleStatusChange("Edit", row)
        }
      } catch (error) {
        alert("An error occurs trying to update WOS Approval Status, please try again.")
      } finally {
        setLoading(false)
      }
    },
    [handleStatusChange, updateWorkOrderService]
  )

  const getSites = useCallback(
    async (signal?: AbortSignal, reset: boolean = false) => {
      try {
        setInputLoading(true)
        let response: AccountResponse | undefined = undefined

        if (siteList.length && validationState.currentFilter?.siteId?.length && value.length === 0 && !reset) {
          const siteName = siteList.find((s) => s.id === validationState.currentFilter?.siteId)?.label ?? ""

          response = await getAccounts("site", siteName, undefined, signal)
        } else {
          response = await getAccounts("site", value, undefined, signal)
        }

        if (!response) return

        const result = response.accounts.map((s) => ({ id: s.sfid, label: s.name })).sort((a, b) => (a.label > b.label ? 1 : -1))

        setSiteList(result)
      } catch (error) {
        console.log("ERROR GETTING SITES", error)
      } finally {
        setInputLoading(false)
      }
    },
    [getAccounts, siteList, validationState, value]
  )

  useEffect(() => {
    const abortController = new AbortController()

    if (currentTab === 0) {
      handleValidationPageChange(undefined, undefined, undefined, undefined, undefined, abortController.signal).then(() =>
        getSites(abortController.signal)
      )
    } else {
      handleSnowWorklogsPageChange(undefined, undefined, undefined, undefined, undefined, abortController.signal).then(() =>
        getSites(abortController.signal)
      )
    }

    return () => {
      abortController.abort()
    }
  }, [currentTab, validationState.perPageWithStorm, validationState.perPageWithoutStorm])

  useEffect(() => {
    const abortController = new AbortController()

    if (value.length > 0) {
      getSites(abortController.signal)
    }

    return () => {
      abortController.abort()
    }
  }, [value])

  const snowSortHandler = useCallback(
    async (by: SortField) => {
      if (!validationState?.snowSort) return

      let sortValue: { by: SortField; order: "ASC" | "DESC" } = { by, order: "ASC" }

      if (by === validationState?.snowSort.by) {
        sortValue = {
          by,
          order: validationState?.snowSort.order === "ASC" ? "DESC" : "ASC",
        }
      } else {
        sortValue = {
          by,
          order: "ASC",
        }
      }

      try {
        try {
          await handleValidationPageChange(undefined, true, sortValue)
        } catch (error) {
          console.log("error", error)
        } finally {
          setLoading(false)
        }
      } catch (error) {
        console.log(error)
      }
    },
    [handleValidationPageChange, validationState]
  )

  const workOrderSortHandler = useCallback(
    async (by: SortField) => {
      if (!validationState?.workOrderSort) return

      let sortValue: { by: SortField; order: "ASC" | "DESC" } = { by, order: "ASC" }

      if (by === validationState?.workOrderSort.by) {
        sortValue = {
          by,
          order: validationState?.workOrderSort.order === "ASC" ? "DESC" : "ASC",
        }
      } else {
        sortValue = {
          by,
          order: "ASC",
        }
      }

      try {
        try {
          await handleSnowWorklogsPageChange(undefined, true, sortValue)
        } catch (error) {
          console.log("error", error)
        } finally {
          setLoading(false)
        }
      } catch (error) {
        console.log(error)
      }
    },
    [handleSnowWorklogsPageChange, validationState]
  )

  const getUnderOverCounterLabel = (items: ValidationsItem[]) => {
    const result = {
      under: 0,
      over: 0,
    }

    items.forEach((item) => {
      const under = item?.services?.filter((s) => s.count > 0 && s.count < (s.min ?? 0))?.length

      const over = item?.services?.filter((s) => s.count > (s.max ?? 0))?.length

      result.under += under ?? 0

      result.over += over ?? 0
    })

    return result
  }

  return (
    <>
      {validationState?.validationType === ValidationTypes.Land && validationState?.selectedValidation !== null ? (
        <LandDetailsComponent />
      ) : (
        <>
          {validationState?.validationType === ValidationTypes.Snow ? (
            <Grid container>
              <Modal open={loading} className="loadingModalContainer">
                <CircularProgress />
              </Modal>

              <Grid item xs={12}>
                <div className="w-full">
                  <Box sx={{ width: "100%" }}>
                    <Box
                      sx={{
                        borderColor: "divider",
                      }}
                    >
                      <div className="mb-3 h-8 w-full">
                        <div className="float-left h-6 w-1/2 text-center">
                          <Button
                            variant="text"
                            sx={{
                              border: "2px solid",
                              borderColor: currentTab === 0 ? "#0F2150" : "#E5E5E5",
                              borderRadius: "5px 0px 0px 0px",
                              color: "#0F2150",
                              backgroundColor: currentTab === 0 ? "white" : "#E5E5E5",
                              fontWeight: "bold",
                              width: "100%",
                              "&:hover": {
                                borderColor: "#0F2150",
                              },
                            }}
                            onClick={() => {
                              setCurrentTab(0)
                            }}
                          >
                            WO With Storms
                          </Button>
                        </div>
                        <div className="float-left h-6 w-1/2 text-center">
                          <Button
                            variant="text"
                            sx={{
                              border: "2px solid",
                              borderColor: currentTab === 1 ? "#0F2150" : "#E5E5E5",
                              borderRadius: "0px 5px 0px 0px",
                              color: "#0F2150",
                              backgroundColor: currentTab === 1 ? "white" : "#E5E5E5",
                              fontWeight: "bold",
                              width: "100%",
                              "&:hover": {
                                borderColor: "#0F2150",
                              },
                            }}
                            onClick={() => setCurrentTab(1)}
                          >
                            WO Without Weather
                          </Button>
                        </div>
                      </div>
                    </Box>

                    <div className="titleRow">
                      <div className="flex flex-row items-center gap-4">
                        <div>
                          <Typography noWrap className="primaryTitle">
                            Search Results
                          </Typography>
                          <p className="ml-[40px] text-xs font-semibold">{`${validationState.user?.firstname} ${validationState.user?.lastname} - ${validationState.user?.title}`}</p>
                        </div>
                        <Typography noWrap className="secondaryTitle">
                          {`from ${validationState?.startDate} to ${validationState?.endDate}`}
                        </Typography>
                      </div>
                      <div className="mr-5 rounded-xl bg-gray-bid-dates p-2">
                        <AcUnitIcon fontSize="large" style={{ color: "#448ab2" }} />
                      </div>
                    </div>
                    <div className="filterRowContainer">
                      <p>Filter:</p>
                      <div className="filterRowOptions pr-4">
                        {currentTab === 0 && (
                          <Select
                            defaultValue=""
                            value={validationState.currentFilter?.acc}
                            onChange={(e) => {
                              const temp = [...(validationState?.validations ?? [])] as Validation[]

                              const result =
                                temp.filter((item) => {
                                  const acc = +item.storm.total_accumulation

                                  if (e.target.value === "0-3") {
                                    return acc >= 0 && acc <= 3
                                  }

                                  if (e.target.value === "3-6") {
                                    return acc > 3 && acc <= 6
                                  }

                                  if (e.target.value === "6-10") {
                                    return acc > 6 && acc <= 10
                                  }

                                  if (e.target.value === "10+") {
                                    return acc > 10
                                  }

                                  return false
                                }) ?? []

                              setValidation({
                                ...validationState,
                                filteredValidations: result,
                                currentFilter: {
                                  ...validationState.currentFilter,
                                  acc: e.target.value,
                                },
                              })
                            }}
                            displayEmpty
                            size="medium"
                            inputProps={{ "aria-label": "Without label" }}
                            className="filterSelect"
                            startAdornment={
                              <InputAdornment position="start">
                                <AcUnitOutlinedIcon />
                              </InputAdornment>
                            }
                          >
                            <MenuItem disabled value={""}>
                              Accumulation
                            </MenuItem>
                            <MenuItem value={"all"}>All</MenuItem>
                            <MenuItem value={"0-3"}>0" - 3"</MenuItem>
                            <MenuItem value={"3-6"}>3" - 6"</MenuItem>
                            <MenuItem value={"6-10"}>6" - 10"</MenuItem>
                            <MenuItem value={"10+"}>10" and above</MenuItem>
                          </Select>
                        )}

                        <Autocomplete
                          freeSolo
                          size="medium"
                          sx={{ maxWidth: 500, minWidth: 400 }}
                          loading={inputLoading}
                          loadingText="Loading..."
                          value={siteList.find((s) => s.id === validationState?.currentFilter?.siteId)?.label ?? ""}
                          options={siteList}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              InputLabelProps={{
                                ...params.InputLabelProps,
                                shrink: true,
                              }}
                              InputProps={{
                                ...params.InputProps,
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <LocationOnIcon />
                                  </InputAdornment>
                                ),
                              }}
                              onChange={(e) => setSiteInputText(e.target.value)}
                              label="Site"
                            />
                          )}
                          onInputChange={(e, value, r) => {
                            if (r === "clear") {
                              setValidation({
                                ...validationState,
                                currentFilter: {
                                  ...validationState.currentFilter,
                                  siteId: "",
                                },
                                selectedService: undefined,
                                validations: [],
                              })

                              if (currentTab === 0) {
                                handleValidationPageChange(undefined, true, undefined, undefined, "").then(() => getSites(undefined, true))
                              } else {
                                handleSnowWorklogsPageChange(undefined, true, undefined, undefined, "").then(() => getSites(undefined, true))
                              }
                            }
                          }}
                          onChange={(e, value, r, d) => {
                            if (r !== "clear") {
                              setValidation({
                                ...validationState,
                                currentFilter: {
                                  ...validationState.currentFilter,
                                  siteId: (value as any).id ?? "",
                                },
                                selectedService: undefined,
                                validations: currentTab === 0 ? [] : validationState?.validations ?? [],
                                workOrders: currentTab === 1 ? [] : validationState?.workOrders ?? [],
                              })

                              if (currentTab === 0) {
                                handleValidationPageChange(undefined, true, undefined, undefined, (value as any).id ?? "")
                              } else {
                                handleSnowWorklogsPageChange(undefined, true, undefined, undefined, (value as any).id ?? "")
                              }
                            }
                          }}
                        />
                        <Select
                          defaultValue=""
                          value={validationState?.currentFilter?.status}
                          onChange={async (e) => {
                            try {
                              if (currentTab === 0) {
                                await handleValidationPageChange(
                                  undefined,
                                  true,
                                  undefined,
                                  e.target.value as "all" | "ReviewPending" | "ReviewCompleted"
                                )
                              } else {
                                await handleSnowWorklogsPageChange(
                                  undefined,
                                  true,
                                  undefined,
                                  e.target.value as "all" | "ReviewPending" | "ReviewCompleted"
                                )
                              }
                            } catch (error) {
                              console.log("error", error)
                            } finally {
                              setLoading(false)
                            }
                          }}
                          displayEmpty
                          size="medium"
                          inputProps={{ "aria-label": "Without label" }}
                          className="filterSelect"
                          startAdornment={
                            <InputAdornment position="start">
                              <DoneAllOutlinedIcon />
                            </InputAdornment>
                          }
                        >
                          <MenuItem disabled value={""}>
                            Status
                          </MenuItem>
                          <MenuItem value={"all"}>All</MenuItem>
                          <MenuItem value={"ReviewPending"}>Pending</MenuItem>
                          <MenuItem value={"ReviewCompleted"}>Completed</MenuItem>
                        </Select>
                      </div>
                    </div>

                    <div className="px-4">
                      {currentTab === 0 ? (
                        <>
                          <SnowValidationTable
                            data={
                              ((validationState?.currentFilter?.acc && validationState?.currentFilter?.acc !== "all"
                                ? validationState?.filteredValidations
                                : validationState.validations) ?? []) as Validation[]
                            }
                            sort={validationState.snowSort}
                            loading={loading}
                            getApprovedValidationsCount={(v) => getWorkOrdersCountByStatus("Approved", v)}
                            getPendingValidationsCount={(v) => getWorkOrdersCountByStatus("Pending", v)}
                            sortHandler={snowSortHandler}
                            onRowClick={(v) => {
                              const pageWithStorm = validationState?.pageWithStorm ?? 0

                              setValidation({
                                ...validationState,
                                pageWithStorm,
                                siteList,
                                selectedValidation: v,
                                snowNextPageToken: validationState.snowPageTokenList?.[pageWithStorm - 1],
                              })

                              navigate("details", {
                                state: {
                                  selectedValidation: v,
                                },
                              })
                            }}
                          />

                          {!loading && validationState?.validations?.length ? (
                            <PaginationComponent
                              page={validationState?.pageWithStorm ?? 1}
                              perPage={validationState?.perPageWithStorm ?? 10}
                              total={validationState?.totalWithStorm ?? 1}
                              loading={loading}
                              quantity={validationState?.validations.length}
                              onNextPage={() => handleValidationPageChange("next")}
                              onPrevPage={() => handleValidationPageChange("prev")}
                              onPageSizeChange={(v) => {
                                setValidation({
                                  ...validationState,
                                  pageWithStorm: 1,
                                  perPageWithStorm: v,
                                  snowPageTokenList: [],
                                  snowNextPageToken: "",
                                })
                              }}
                            />
                          ) : (
                            <></>
                          )}

                          <br />
                        </>
                      ) : (
                        <></>
                      )}

                      {currentTab === 1 ? (
                        <>
                          {loading ? (
                            <Loader />
                          ) : (
                            <SnowValidationWithoutWeatherTable
                              data={validationState?.workOrders ?? []}
                              sort={validationState.workOrderSort}
                              sortHandler={workOrderSortHandler}
                              handleStatusChange={handleStatusChange}
                              handleServiceStatusChange={handleServiceStatusChange}
                              onNotesUpdate={(note, workLogId) => {
                                const newWorkOrders = validationState?.workOrders?.map((wo) => {
                                  if (wo.workLogId === workLogId) {
                                    return {
                                      ...wo,
                                      notes: note,
                                    }
                                  }

                                  return wo
                                })

                                setValidation({
                                  ...validationState,
                                  workOrders: newWorkOrders,
                                })
                              }}
                            />
                          )}

                          {!loading && validationState?.workOrders?.length ? (
                            <PaginationComponent
                              page={validationState?.pageWithoutStorm ?? 1}
                              perPage={validationState?.perPageWithoutStorm ?? 10}
                              total={validationState?.totalWithoutStorm ?? 1}
                              loading={loading}
                              quantity={validationState?.workOrders?.length ?? 0}
                              onNextPage={() => handleSnowWorklogsPageChange("next")}
                              onPrevPage={() => handleSnowWorklogsPageChange("prev")}
                              onPageSizeChange={(v) => {
                                setValidation({
                                  ...validationState,
                                  pageWithoutStorm: 1,
                                  perPageWithoutStorm: v,
                                  workOrderPageTokenList: [],
                                  workOrderNextPageToken: "",
                                })
                              }}
                            />
                          ) : (
                            <></>
                          )}
                        </>
                      ) : (
                        <></>
                      )}
                    </div>
                  </Box>
                </div>
              </Grid>
            </Grid>
          ) : (
            <div className="titleRow">
              <div className="flex flex-row items-center gap-4">
                <div>
                  <Typography noWrap className="primaryTitle">
                    Search Results
                  </Typography>
                  <p className="ml-[40px] text-xs font-semibold">{`${validationState.user?.firstname} ${validationState.user?.lastname} - ${validationState.user?.title}`}</p>
                </div>
              </div>

              <div className="float-right flex flex-row items-center">
                <div className="mr-4 mt-4 grid grid-cols-2 gap-4 rounded-lg border-2 px-4 py-2">
                  <div className="items-center justify-center">
                    <div className="mb-2 flex flex-row items-center gap-1">
                      <LandChartStatusIconComponent statusColor={LandStatusColor.Validation} />
                      <label>Validation</label>
                    </div>
                    <div className="mb-2 flex flex-row items-center gap-1">
                      <LandChartStatusIconComponent statusColor={LandStatusColor.Missing} />
                      <label>Missing</label>
                    </div>
                    <div className="mb-2 flex flex-row items-center gap-1">
                      <LandChartStatusIconComponent statusColor={LandStatusColor.ReminderBang} />
                      <label>Reminder Exceeded</label>
                    </div>
                  </div>

                  <div>
                    <div className="mb-2 text-center">
                      <p className="font-bold"># Actual / # Expected</p>
                    </div>
                    <div className="mb-2 flex flex-row items-center gap-1">
                      <LandChartStatusIconComponent statusColor={LandStatusColor.BlackStar} />
                      <label>Reviewed</label>
                    </div>
                    <div className="mb-2 flex flex-row items-center gap-1">
                      <LandChartStatusIconComponent statusColor={LandStatusColor.Reminder} />
                      <label>Reminder</label>
                    </div>
                  </div>
                </div>

                <div className="mr-5 rounded-xl bg-gray-bid-dates p-2">
                  <TreeIcon className="h-10 w-10" />
                </div>
              </div>
            </div>
          )}
        </>
      )}

      <Container className={classes.container}>
        <>
          {validationState?.validationType === ValidationTypes.Land && (
            <>
              {validationState?.selectedValidation === null && <LandFilteringComponent />}

              <LandMainPageComponent />
            </>
          )}
        </>
      </Container>

      <Snackbar
        open={isAutoApproving}
        autoHideDuration={25000}
        onClose={() => setAutoApproving(false)}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={() => setAutoApproving(false)}
          severity="info"
          sx={{ width: "100%" }}
          style={{
            backgroundColor: "#0F2150",
            color: "white",
            fontSize: 18,
            alignItems: "center",
          }}
        >
          Auto-Review in progress, please wait...
        </Alert>
      </Snackbar>
    </>
  )
}

export default ValidationResults
