import { useCallback, useEffect, useState } from "react"
import { makeStyles, createStyles, Container, Grid, Divider, Checkbox } from "@material-ui/core"
import Typography from "@mui/material/Typography"
import Map from "../../../common/googleMap"
import useI18n from "../../../../hooks/useI18n"
import { useGraphQLService } from "../../../../services/graphqlService"
import { TailSpin } from "react-loader-spinner"

export const ProfileTab = (props: any) => {
  const classes = useStyles()
  const { i18n, locale, saveLocale } = useI18n()
  const [partner, setPartner] = useState<any>({})
  const [optOut, setOptOut] = useState(false)
  const [isLoading, setLoading] = useState(false)

  const graphqlService = useGraphQLService()

  useEffect(() => {
    setPartner(props?.partner)
    setOptOut(props?.partner?.sp_opts_out_of_wo_confirmation_emails ?? false)
  }, [props?.partner])

  const handleOptOutChange = useCallback(async () => {
    let newValue = !optOut

    try {
      if (!partner) return

      setOptOut(newValue)

      setLoading(true)

      await graphqlService.updateServicePartnerEquipment({
        sfid: partner.sfid,
        values: {
          sp_opts_out_of_wo_confirmation_emails: optOut,
        },
      })
    } catch (error) {
      newValue = !newValue

      throw new Error(`Error on updating SP: ${error}`)
    } finally {
      setOptOut(newValue)
      setLoading(false)
    }
  }, [graphqlService, partner, optOut])

  return (
    <>
      <div className={classes.listContainer}>
        <div className={classes.grid}>
          <Container className={classes.container}>
            <Grid container>
              <Grid item xs={12}>
                <Typography>
                  <div className={classes.titleFont}>{i18n.t("breadcrumbs.profile")}</div>
                </Typography>
              </Grid>

              <Grid item xs={4}>
                <Typography>
                  <div className={classes.subtitleFont}>{i18n.t("profile.personalInfo")}</div>
                </Typography>
                <Typography>
                  <div className={classes.subtitleIndicator}>{i18n.t("profile.providePersonalInfo")}</div>
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Grid container>
                  <Grid item xs={4}>
                    <Typography>
                      <div className={classes.labelFont}>{i18n.t("profile.firstName")}</div>
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <Typography>
                      <div>{partner?.first_name || "-"}</div>
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography>
                      <div className={classes.labelFont}>{i18n.t("profile.lastName")}</div>
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <Typography>
                      <div>{partner?.last_name || "-"}</div>
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography>
                      <div className={classes.labelFont}>{i18n.t("profile.phoneNumber")}</div>
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <Typography>
                      <div>{partner?.primary_field_poc_cell || "-"}</div>
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography>
                      <div className={classes.labelFont}>{i18n.t("profile.email")}</div>
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <Typography>
                      <div>{partner?.primary_field_poc_email || "-"}</div>
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography>
                      <div className={classes.labelFont}>{i18n.t("profile.optOutWOConfirmationEmails")}</div>
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    {isLoading ? (
                      <TailSpin color="#132957" width={20} height={20} />
                    ) : (
                      <Checkbox size="small" checked={optOut} style={{ padding: 0, color: "#132957" }} onChange={handleOptOutChange} />
                    )}
                  </Grid>
                  <Grid item xs={4}>
                    <Typography>
                      <div className={classes.labelFont}>{i18n.t("common.language")}</div>
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <select value={locale} className="border-gray-300 w-min rounded-md border p-2" onChange={(e) => saveLocale(e.target.value)}>
                      <option value="en">{i18n.t("common.en")}</option>
                      <option value="fr">{i18n.t("common.fr")}</option>
                    </select>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12} className={classes.divider}>
                <Divider variant="middle" />
              </Grid>

              <Grid item xs={4}>
                <Typography>
                  <div className={classes.subtitleFont}>{i18n.t("profile.companyInfo")}</div>
                </Typography>
                <Typography>
                  <div className={classes.subtitleIndicator}>{i18n.t("profile.provideCompanyInfo")}</div>
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Grid container>
                  <Grid item xs={4}>
                    <Typography>
                      <div className={classes.labelFont}>{i18n.t("profile.companyName")}</div>
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <Typography>
                      <div>{partner?.name || "-"}</div>
                    </Typography>
                    {partner?.billinglatitude && (
                      <div className="relative mb-4">
                        <Map
                          center={{
                            lat: partner?.billinglatitude,
                            lng: partner?.billinglongitude,
                          }}
                          principalMarkers={[
                            {
                              id: 0,
                              name: partner?.name,
                              lat: partner?.billinglatitude,
                              lng: partner?.billinglongitude,
                            },
                          ]}
                          siteMarkers={[]}
                          pendingBidMarkers={[]}
                          openBidMarkers={[]}
                          isLoaded={true}
                          maxHeight={"200px"}
                          setMarker={false}
                          defaultMarker={0}
                          zoom={11}
                        />
                      </div>
                    )}
                  </Grid>

                  <Grid item xs={4}>
                    <Typography>
                      <div className={classes.labelFont}>{i18n.t("profile.companyAddress")}</div>
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <Typography>
                      <div>
                        {partner?.billingstreet}
                        {", "}
                        {partner?.billingcity}
                        {", "}
                        {partner?.billingstate}
                        {", "}
                        {partner?.billingpostalcode}
                      </div>
                    </Typography>
                  </Grid>

                  <Grid item xs={4}>
                    <Typography>
                      <div className={classes.labelFont}>{i18n.t("profile.companyWebsite")}</div>
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <Typography>
                      <div>{partner?.website || "-"}</div>
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Container>
        </div>
      </div>
    </>
  )
}

const useStyles = makeStyles(() =>
  createStyles({
    listContainer: {
      width: "auto",
      marginLeft: 0,
      padding: "10px",
    },
    grid: {
      border: "1px solid #E0E0E0",
      borderRadius: "5px",
      background: "#FFFFFF",
      // cursor: "pointer",
    },
    container: {
      maxWidth: "100%",
      padding: "20px",
    },
    titleFont: {
      fontSize: "25px",
      fontWeight: 800,
      lineHeight: "113%",
      color: "#0F2150",
      marginBottom: "20px",
    },
    subtitleFont: {
      fontSize: "20px",
      fontWeight: 800,
      lineHeight: "113%",
      color: "#0F2150",
    },
    subtitleIndicator: {
      fontSize: "15px",
      color: "gray",
      letterSpacing: "-1px",
    },
    labelFont: {
      fontSize: "18px",
      fontWeight: "bold",
      lineHeight: "113%",
      color: "#0F2150",
      marginBottom: "20px",
    },
    divider: {
      marginTop: "10px",
      marginBottom: "10px",
    },
  })
)

export default ProfileTab
