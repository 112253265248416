import { useCallback, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { Container, Grid, Typography } from "@material-ui/core"

import { useActiveUser, useAuth } from "../../providers/AuthProvider"
import { useAdminStyles } from "../Admin/styles"
import { ReactComponent as RightIcon } from "./../../assets/right.svg"
import useI18n from "../../hooks/useI18n"
import { DirectPaymentForm } from "./components/directPaymentForm"
import { Loader } from "../common/Loader"
import { useGraphQLService } from "../../services/graphqlService"
import { Alert, AlertTitle } from "@mui/material"

export const DirectPaymentPage = () => {
  const { isCanada } = useAuth()
  const classes = useAdminStyles()
  const { i18n } = useI18n()
  const navigate = useNavigate()
  const [isLoading, setLoading] = useState(false)
  const [showThanks, setShowThanks] = useState(false)
  const [partner, setPartner] = useState<any>({})
  const { sfId: sfid } = useActiveUser()
  const graphqlService = useGraphQLService()

  const getPartnerData = useCallback(
    (signal?: AbortSignal) => {
      setLoading(true)
      graphqlService
        .getPartnerBySfid({ sfid }, signal)
        .then((response) => {
          setPartner(response)
        })
        .finally(() => {
          setLoading(false)
        })
    },
    [graphqlService, sfid]
  )

  useEffect(() => {
    const controller = new AbortController()

    getPartnerData(controller.signal)

    return () => controller.abort()
  }, [getPartnerData])

  return (
    <Container className={classes.container}>
      <Grid container>
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={12}>
              <div className={classes.showTitle}>
                <div className="mb-4">
                  <Typography noWrap className="flex flex-row items-center">
                    <span className="cursor-pointer  font-bold text-breadcrumb-blue" onClick={() => navigate("/")}>
                      {i18n.t("breadcrumbs.home")}
                    </span>
                    <RightIcon className="ml-3 mr-3 w-3.5" />
                    <span className="font-bold">{i18n.t("breadcrumbs.directPaymentSignIn")}</span>
                  </Typography>
                </div>
              </div>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          {
            <div className="m-1 flex flex-col items-center justify-center rounded-md border border-[#E0E0E0] bg-white p-4">
              {!isCanada ? (
                <span className="flex flex-col gap-4">
                  <h1 className="text-xl font-bold">{i18n.t("directPayment.notAvailable")}</h1>
                  <p>{i18n.t("directPayment.directPaymentIsNotAvailableInUS")}</p>
                </span>
              ) : isLoading ? (
                <Loader />
              ) : (
                <>
                  <div className="mb-8 flex flex-col gap-4 lg:mb-0 lg:w-9/12">
                    {showThanks && (
                      <div className="mb-4 shadow-lg">
                        <Alert severity="success">
                          <AlertTitle>{i18n.t("directPayment.thanks")}</AlertTitle>
                          <span className="text-lg font-bold">{i18n.t("directPayment.thanksMessage")}</span>
                        </Alert>
                      </div>
                    )}

                    <h1 className="text-xl font-bold">{i18n.t("directPayment.enrollInDirectDepositToday")}</h1>
                    <p>{i18n.t("directPayment.enjoyFasterMoreSecureAccess")}</p>
                  </div>

                  <div className="flex flex-col-reverse items-center justify-center gap-6 lg:flex-row">
                    <DirectPaymentForm partner={partner} showThanks={showThanks} setShowThanks={setShowThanks} />
                    <img
                      src={require("../../assets/ck-example.png")}
                      alt="ck-example"
                      className="h-full w-full object-cover md:h-1/2 md:w-1/2 lg:h-1/3 lg:w-1/3"
                    />
                  </div>
                </>
              )}
            </div>
          }
        </Grid>
      </Grid>
    </Container>
  )
}
