import React, { useCallback, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { Loader } from "../../common/Loader"
import { makeStyles, createStyles, Container, Grid, Typography, Button } from "@material-ui/core"
import { ReactComponent as DownIcon } from "./../../../assets/down.svg"
import { ReactComponent as ErrorIcon } from "./../../../assets/circle-red.svg"
import { formatDate, getMinFormat } from "../../../utils/Util"
import Divider from "@mui/material/Divider"
import { useActiveUser } from "../../../providers/AuthProvider"
import { useGraphQLService } from "../../../services/graphqlService"

export const WorkOrdersLists = (props: any) => {
  const classes = useStyles()
  const navigate = useNavigate()
  const graphqlService = useGraphQLService()

  const [search, searchInput] = useState("")
  const [data, setData] = useState<any[]>([])
  const [sort, setSort] = useState({ field: "service_date", order: "DESC" })
  const [loading, setLoading] = useState(false)
  const [loadingMore, setLoadingMore] = useState(false)
  const [error, setError] = useState("")
  const [total, setTotal] = useState(0)
  const [page, setPage] = useState(1)
  const [perPage, setPerPage] = useState(10)
  const [partnerInfo, setPartnerInfo] = useState<any>(null)
  const { sfId: sfid } = useActiveUser()

  const getPartnerData = useCallback(
    (signal?: AbortSignal) => {
      graphqlService
        .getPartnerBySfid({ sfid }, signal)
        .then((response) => {
          setData(response)
        })
        .catch(() => {})
        .finally(() => setLoading(false))
    },
    [graphqlService, sfid]
  )

  useEffect(() => {
    const controller = new AbortController()

    if (page === 1) {
      setLoading(true)
    } else {
      setLoadingMore(true)
    }
    graphqlService
      .getWorkOrders(
        {
          providerId: sfid,
          limit: perPage,
          offset: (page - 1) * perPage,
          sort: sort.field,
          order: sort.order,
          search: props?.filter?.search,
        },
        controller.signal
      )
      .then(async (response) => {
        setData((d) => [...d, ...response.worklogs])
        setTotal(response.pagination.found_rows)

        if (props.isAutoApproved === undefined && !partnerInfo) {
          try {
            const resp = await getPartnerData()

            setPartnerInfo(resp)
          } catch (error) {
            console.log(error)
          }
        }
      })
      .finally(() => {
        setLoading(false)
        setLoadingMore(false)
      })

    return () => controller.abort()
  }, [graphqlService, page, perPage, props?.filter, sfid, sort])

  const showDetails = (order: any) => {
    navigate(`/workorder-details?id=${order.sfid}`)
  }

  if (error) {
    return <p>ERROR: {error}</p>
  } else {
    return (
      <>
        {loading && (
          <div className={classes.listContainer}>
            <Loader />
          </div>
        )}

        {!loading && !data.length && (
          <div className={classes.listContainer}>
            <div className="flex flex-col items-center">EMPTY</div>
          </div>
        )}

        {!loading && !!data.length && (
          <div>
            <Container className={classes.container}>
              {data.map((row) => (
                <Grid key={row?.sfid} container onClick={() => showDetails(row)} style={{ cursor: "pointer" }}>
                  <Grid item xs={12}>
                    <div className={classes.grid}>
                      <div className={classes.secondaryTitle}>
                        <Typography noWrap className={classes.cardTitle}>
                          <div className="flex flex-row items-center">
                            <span className="mr-3">{row.name}</span>
                            <span>{row.cancelled ? <ErrorIcon className="w-2" /> : ""}</span>
                          </div>
                        </Typography>
                      </div>
                      <div className={classes.divider}>
                        <Divider variant="middle" className={classes.dividerMargin} />
                      </div>
                      <div className={classes.data}>
                        <div className="bold mb-1 text-case-d-gray">{row.location_name}</div>
                        <div className="bold mb-1 text-case-d-gray">
                          Service Summary: <span className={classes.cardDetails}>{row.service_summary}</span>
                        </div>
                        <div className="bold mb-1 text-case-d-gray">
                          Date Reported: <span className={classes.cardDetails}>{row.service_date ? formatDate(row.service_date) : "-"}</span>
                        </div>
                        <div className="bold text-case-d-gray">
                          Time Taken On Time:{" "}
                          <span className={classes.cardDetails}>{row?.time_taken_minutes ? getMinFormat(row?.time_taken_minutes) : "-"}</span>
                        </div>
                      </div>
                    </div>
                  </Grid>
                </Grid>
              ))}
              {loadingMore ? (
                <Loader />
              ) : (
                <Grid container>
                  <Grid item xs={12}>
                    <div className="align-items flex w-full flex-col">
                      <Button variant="text" size="small" onClick={() => setPage(page + 1)}>
                        <DownIcon />
                      </Button>
                    </div>
                  </Grid>
                </Grid>
              )}
            </Container>
          </div>
        )}
      </>
    )
  }
}
const useStyles = makeStyles(() =>
  createStyles({
    listContainer: {
      width: "auto",
      marginLeft: 0,
    },
    location: {
      fontWeight: "bold",
      color: "#1695CB",
    },
    cardTitle: {
      fontWeight: 800,
      lineHeight: "113%",
      color: "#232F64",
      letterSpacing: "-1px",
      fontSize: "28px",
      padding: "0px 10px 0px 10px",
    },
    grid: {
      border: "1px solid #E0E0E0",
      borderRadius: "5px",
      margin: "5px",
      background: "#FFFFFF",
      padding: "8px",
    },
    container: {
      maxWidth: "100%",
    },
    secondaryTitle: {
      display: "flex",
      justifyContent: "space-between",
      paddingBottom: "5px",
    },
    data: {
      padding: "0px 10px 20px 10px",
    },
    cardDetails: {
      fontWeight: "bold",
      color: "#0F2150",
    },
    greenStatus: {
      backgroundColor: "#d7f5e3",
      color: "#4e996c",
      borderRadius: "20px",
      padding: "5px 10px",
      fontSize: "15px",
      margin: "5px",
    },
    redStatus: {
      backgroundColor: "#fed5d5",
      color: "#ff3b5f",
      borderRadius: "20px",
      padding: "5px 10px",
      fontSize: "15px",
      margin: "5px",
    },
    yellowStatus: {
      backgroundColor: "#ffd978",
      color: "#665730",
      borderRadius: "20px",
      padding: "5px 10px",
      fontSize: "15px",
      margin: "5px",
    },
    divider: {
      marginTop: "10px",
      marginBottom: "10px",
    },
    dividerMargin: {
      margin: "0px 7px!important",
    },
  })
)

export default WorkOrdersLists
