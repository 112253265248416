import { useState } from "react"
import { makeStyles, createStyles, Container, Grid, Typography } from "@material-ui/core"
import { useNavigate } from "react-router-dom"
import { ReactComponent as RightIcon } from "./../../../assets/right.svg"
import { WorkOrdersFilters } from "./WorksOrdersFilter"
import WorkOrdersLists from "./WorkOrdersList"
import useI18n from "../../../hooks/useI18n"

function WorkOrdersDesktop(props: any) {
  const navigate = useNavigate()
  const classes = useStyles()
  const { i18n } = useI18n()
  const [filter, setFilter] = useState<any>({})

  return (
    <>
      <div>
        <Container className={classes.container}>
          <Grid container>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={6}>
                  <div className={classes.showTitle}>
                    <div className="mb-4">
                      <Typography noWrap className="flex flex-row items-center">
                        <span className="cursor-pointer font-bold text-breadcrumb-blue" onClick={() => navigate("/")}>
                          {i18n.t("breadcrumbs.home")}
                        </span>
                        <RightIcon className="ml-3 mr-3 w-3.5" />
                        <span className="font-bold">{i18n.t("breadcrumbs.workOrderListing")}</span>
                      </Typography>
                    </div>
                    <div>
                      <Typography noWrap className={classes.titleFont}>
                        {i18n.t("workOrders.yourWorkOrders")}
                      </Typography>
                    </div>
                  </div>
                </Grid>
              </Grid>
              <div className={classes.grid}>
                <WorkOrdersFilters filter={filter} setFilter={setFilter} />
                <WorkOrdersLists {...props} search={""} filter={filter} />
              </div>
            </Grid>
          </Grid>
        </Container>
      </div>
    </>
  )
}

const useStyles = makeStyles(() =>
  createStyles({
    showTitle: {
      width: "auto",
      marginTop: "10px",
      marginLeft: "10px",
    },
    titleFont: {
      fontSize: "44px",
      fontWeight: 800,
      lineHeight: "113%",
      color: "#000",
      letterSpacing: "-1px",
    },
    secondaryTitleFont: {
      fontSize: "18px",
      lineHeight: "113%",
      color: "#000",
      letterSpacing: "-1px",
      marginTop: "10px",
      marginBottom: "20px",
    },
    cardTitle: {
      fontWeight: 800,
      lineHeight: "113%",
      color: "#232F64",
      letterSpacing: "-1px",
      marginLeft: "40px",
      marginTop: "21px",
    },
    grid: {
      border: "1px solid #E0E0E0",
      borderRadius: "5px",
      margin: "5px",
      background: "#FFFFFF",
    },
    container: {
      maxWidth: "100%",
    },
  })
)

export default WorkOrdersDesktop
