import React, { useRef, useState } from "react"

import { Button, TextField } from "@material-ui/core"
import axios from "axios"

import { Loader } from "../../common/Loader"
import useI18n from "../../../hooks/useI18n"

export const DirectPaymentForm = ({
  partner,
  showThanks,
  setShowThanks,
}: {
  partner?: any
  showThanks: boolean
  setShowThanks: (v: boolean) => void
}) => {
  const bankNameRef = useRef<HTMLInputElement>(null)
  const bankAddressRef = useRef<HTMLInputElement>(null)
  const transitNumberRef = useRef<HTMLInputElement>(null)
  const bankNumberRef = useRef<HTMLInputElement>(null)
  const accountNumberRef = useRef<HTMLInputElement>(null)
  const [isLoading, setLoading] = useState(false)
  const [file, setFile] = useState<File | null>(null)
  const { i18n } = useI18n()

  if (!partner) return null

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    if (!isFormValid()) {
      return alert(i18n.t("directPayment.pleaseFillOutTheForm"))
    }

    const formData = new FormData()
    const postURL = process.env.REACT_APP_DIRECT_PAYMENT_FORM_URL

    if (!postURL) return alert(i18n.t("directPayment.somethingWentWrong"))

    formData.append("bankName", bankNameRef.current!.value)
    formData.append("bankAddress", bankAddressRef.current!.value)
    formData.append("transitNumber", transitNumberRef.current!.value)
    formData.append("bankNumber", bankNumberRef.current!.value)
    formData.append("accountNumber", accountNumberRef.current!.value)
    formData.append("email", partner?.primary_field_poc_email || "-")
    formData.append("phoneNumber", partner?.primary_field_poc_cell || "-")
    formData.append("name", (partner?.first_name || "-") + " " + (partner?.last_name || "-"))
    formData.append("companyName", partner?.name || "-")
    formData.append("companyAddress", `${partner?.billingstreet}, ${partner?.billingcity}, ${partner?.billingstate}, ${partner?.billingpostalcode}`)

    let base64: string | null = null

    try {
      base64 = await getFileBase64(file!)

      if (!base64) {
        setFile(null)

        return alert(i18n.t("directPayment.anErrorOcuredWhileProcessingTheFile"))
      }
    } catch (error) {
      console.error(error)
      setFile(null)

      return alert(i18n.t("directPayment.anErrorOcuredWhileProcessingTheFile"))
    }

    const lastDot = file!.name.lastIndexOf(".")
    const fileType = file!.name.substring(lastDot + 1)

    formData.append("file", base64)
    formData.append("fileType", fileType)

    try {
      setLoading(true)

      const response = await axios.post(postURL, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })

      if (response.status === 200) {
        setShowThanks(true)
      } else {
        alert(i18n.t("directPaymentForm.anErrorOcuredWhileSubmittingTheForm"))
      }
    } catch (error) {
      return alert(i18n.t("directPaymentForm.anErrorOcuredWhileSubmittingTheForm"))
    } finally {
      setLoading(false)
    }
  }

  const getFileBase64 = (file: File) => {
    try {
      return new Promise<string>((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => resolve(reader.result as string)
        reader.onerror = (error) => reject(error)
      })
    } catch (error) {
      console.error(error)

      return null
    }
  }

  const isFormValid = () =>
    !!bankNameRef.current?.value &&
    !!bankAddressRef.current?.value &&
    !!transitNumberRef.current?.value &&
    !!bankNumberRef.current?.value &&
    !!accountNumberRef.current?.value &&
    !!file

  return (
    <div className="flex flex-1 flex-col lg:w-1/2 lg:flex-initial">
      {isLoading && (
        <div className="absolute bottom-0 left-0 right-0 top-0 flex h-screen w-screen items-center justify-center self-center rounded-xl bg-slate-500 bg-opacity-25 p-4">
          <Loader />
        </div>
      )}

      <form encType="multipart/form-data" className="flex flex-col items-center justify-center gap-4 px-4 py-8" onSubmit={handleSubmit}>
        <fieldset disabled={showThanks || isLoading} className="flex w-full flex-col items-center justify-center gap-4 px-4 py-8">
          <TextField label={i18n.t("directPayment.bankName")} className="w-full" inputRef={bankNameRef} />

          <TextField label={i18n.t("directPayment.bankAddress")} className="w-full" inputRef={bankAddressRef} />

          <TextField label={i18n.t("directPayment.transitNumber")} className="w-full" inputRef={transitNumberRef} />

          <TextField label={i18n.t("directPayment.bankNumber")} className="w-full" inputRef={bankNumberRef} />

          <TextField label={i18n.t("directPayment.accountNumber")} className="w-full" inputRef={accountNumberRef} />

          <div className="flex w-full flex-col gap-4">
            <label htmlFor="file" className="text-lg font-bold">
              {i18n.t("directPayment.uploadVoidedCheck")}
            </label>

            <input
              type="file"
              id="file"
              onChange={(e) => setFile(e.target.files?.item(0) || null)}
              accept="application/msword, text/plain, application/pdf, .docx, .pdf, .doc, image/*"
              className="border-gray-300 w-full rounded-md border p-4 shadow-sm"
            />
          </div>
        </fieldset>

        <br />

        <Button variant="contained" color="primary" size="large" type="submit" className="my-4 w-full py-4" disabled={showThanks || isLoading}>
          {i18n.t("directPayment.submit")}
        </Button>
      </form>
    </div>
  )
}
