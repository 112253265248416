import { useCallback, useEffect, useRef, useState } from "react"
import { makeStyles, createStyles } from "@material-ui/core"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell, { tableCellClasses } from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import Paper from "@mui/material/Paper"
import { styled } from "@mui/material/styles"
import { useNavigate } from "react-router-dom"
import Pagination from "../../common/Pagination"
import { Loader } from "../../common/Loader"
import { ReactComponent as UpIcon } from "./../../../assets/up.svg"
import { ReactComponent as DownIcon } from "./../../../assets/down.svg"
import { distanceInMiles, formatDate, formatDateUTC } from "../../../utils/Util"
import { ReactComponent as SnowIcon } from "./../../../assets/cloud-snow-lb.svg"
import { ReactComponent as TreeIcon } from "./../../../assets/tree-g.svg"
import { ReactComponent as SweepingIcon } from "./../../../assets/sweeping.svg"
import { ReactComponent as JanitoralIcon } from "./../../../assets/janitoral-b.svg"
import { useActiveUser } from "../../../providers/AuthProvider"
import { useGraphQLService } from "../../../services/graphqlService"
import useI18n from "../../../hooks/useI18n"

const usePrevious = <T extends unknown>(value: T): T | undefined => {
  const ref = useRef<T>()
  useEffect(() => {
    ref.current = value
  })
  return ref.current
}

export const SitesLists = ({
  filter,
  fetchedAll,
  fetchedList,
  ...props
}: any) => {
  const classes = useStyles()
  const { i18n } = useI18n()
  const navigate = useNavigate()
  const graphqlService = useGraphQLService()

  const [data, setData] = useState<any[]>([])
  const [sort, setSort] = useState({ field: "status", order: "ASC" })
  const [loading, setLoading] = useState(false)
  const [total, setTotal] = useState(0)
  const [page, setPage] = useState(1)
  const [perPage, setPerPage] = useState(10)
  const { sfId: sfid } = useActiveUser()
  const [partner, setPartner] = useState<any>({})
  const prevFilter = usePrevious({ filter, perPage })

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#f9f9f9",
      color: "#232F64",
      fontSize: "20px",
      fontWeight: "bold",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 18,
    },
  }))

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }))

  const getPartnerData = useCallback(
    (signal?: AbortSignal) => {
      graphqlService
        .getPartnerBySfid({ sfid }, signal)
        .then((response) => {
          setPartner(response)
          fetchedList()
        })
        .catch(() => {})
    },
    [graphqlService, sfid]
  )

  const getSites = useCallback(
    (signal?: AbortSignal) => {
      if (
        (filter !== prevFilter?.filter || perPage !== prevFilter?.perPage) &&
        page !== 1
      ) {
        setPage(1)
      } else {
        setLoading(true)
        graphqlService
          .getSites(
            {
              sfid,
              limit: perPage,
              offset: (page - 1) * perPage,
              sort: sort.field,
              order: sort.order,
              filterBy: filter?.filter === "all" ? "" : filter?.filter,
              search: filter?.search,
            },
            signal
          )
          .then((response) => {
            setData(response.sites || [])
            setTotal(response.pagination.found_rows || 0)
            setLoading(false)
            if (!fetchedAll) {
              getPartnerData(signal)
            }
          })
          .catch(() => {
            setLoading(false)
          })
      }
    },
    [page, perPage, filter, sfid, sort]
  )

  useEffect(() => {
    const controller = new AbortController()

    getSites(controller.signal)

    return () => controller.abort()
  }, [getSites, page, perPage, filter, sfid, sort])

  const sortHandler = (by: any) => {
    if (by === sort.field) {
      if (sort.order === "ASC") {
        setSort({ field: sort.field, order: "DESC" })
      } else {
        setSort({ field: sort.field, order: "ASC" })
      }
    } else {
      setSort({ field: by, order: sort.order })
    }
  }

  const showDetails = (site: any) => {
    navigate(`/site-details?id=${site.sfid}`)
  }

  const distance = (site_lat: any, site_lon: any) => {
    if (partner?.billinglatitude && partner?.billinglongitude) {
      let from = {
        lat: site_lat,
        lon: site_lon,
      }
      let to = {
        lat: partner?.billinglatitude,
        lon: partner?.billinglongitude,
      }
      return distanceInMiles(from, to)
    }
    return ""
  }

  return (
    <>
      <div className={classes.listContainer}>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                <StyledTableCell
                  onClick={() => sortHandler("account_name")}
                  style={{ cursor: "pointer" }}
                >
                  <div className="flex flex-row items-center">
                    {sort.field === "account_name" && sort.order === "DESC" && (
                      <DownIcon />
                    )}
                    {sort.field === "account_name" && sort.order === "ASC" && (
                      <UpIcon />
                    )}
                    <span>{i18n.t("common.tableHeaders.siteName")}</span>
                  </div>
                </StyledTableCell>
                <StyledTableCell
                  onClick={() => sortHandler("service_line")}
                  style={{ cursor: "pointer" }}
                >
                  <div className="flex flex-row items-center">
                    {sort.field === "service_line" && sort.order === "DESC" && (
                      <DownIcon />
                    )}
                    {sort.field === "service_line" && sort.order === "ASC" && (
                      <UpIcon />
                    )}
                    <span>{i18n.t("common.tableHeaders.service")}</span>
                  </div>
                </StyledTableCell>
                <StyledTableCell>
                  <div className="flex flex-row items-center">
                    {sort.field === "work_orders" && sort.order === "DESC" && (
                      <DownIcon />
                    )}
                    {sort.field === "work_orders" && sort.order === "ASC" && (
                      <UpIcon />
                    )}
                    <span>{i18n.t("common.tableHeaders.completedWOs")}</span>
                  </div>
                </StyledTableCell>
                <StyledTableCell>
                  <div className="flex flex-row items-center">
                    {sort.field === "billinglatitude" &&
                      sort.order === "DESC" && <DownIcon />}
                    {sort.field === "billinglatitude" &&
                      sort.order === "ASC" && <UpIcon />}
                    <span>{i18n.t("common.tableHeaders.proximity")}</span>
                  </div>
                </StyledTableCell>
                <StyledTableCell>
                  <div className="flex flex-row items-center">
                    {sort.field === "last_serviced_date" &&
                      sort.order === "DESC" && <DownIcon />}
                    {sort.field === "last_serviced_date" &&
                      sort.order === "ASC" && <UpIcon />}
                    <span>{i18n.t("common.tableHeaders.lastServiceDate")}</span>
                  </div>
                </StyledTableCell>
                <StyledTableCell
                  onClick={() => sortHandler("contract_start_date__c")}
                  style={{ cursor: "pointer" }}
                >
                  <div className="flex flex-row items-center">
                    {sort.field === "contract_start_date__c" &&
                      sort.order === "DESC" && <DownIcon />}
                    {sort.field === "contract_start_date__c" &&
                      sort.order === "ASC" && <UpIcon />}
                    <span>{i18n.t("common.tableHeaders.startDate")}</span>
                  </div>
                </StyledTableCell>
                <StyledTableCell
                  onClick={() => sortHandler("contract_end_date__c")}
                  style={{ cursor: "pointer" }}
                >
                  <div className="flex flex-row items-center">
                    {sort.field === "contract_end_date__c" &&
                      sort.order === "DESC" && <DownIcon />}
                    {sort.field === "contract_end_date__c" &&
                      sort.order === "ASC" && <UpIcon />}
                    <span>{i18n.t("common.tableHeaders.endDate")}</span>
                  </div>
                </StyledTableCell>
              </TableRow>
            </TableHead>
            {fetchedAll && !loading && !!data.length && (
              <TableBody>
                {data.map((row: any, i: any) => (
                  <StyledTableRow
                    hover
                    key={`${i}_${row.sfid}`}
                    onClick={() => showDetails(row)}
                    style={{ cursor: "pointer" }}
                  >
                    <StyledTableCell>{row.account_name}</StyledTableCell>
                    <StyledTableCell>
                      {row.service_line && (
                        <div className="flex flex-row items-center">
                          {row.service_line.indexOf("Snow") >= 0 ? (
                            <SnowIcon className="w-7" />
                          ) : (
                            ""
                          )}
                          {row.service_line.indexOf("Land") >= 0 ? (
                            <TreeIcon className="w-7" />
                          ) : (
                            ""
                          )}

                          {row.service_line.indexOf("Sweeping") >= 0 ? (
                            <SweepingIcon className="w-7" />
                          ) : (
                            ""
                          )}

                          {row.service_line.indexOf("Janitorial") >= 0 ? (
                            <JanitoralIcon className="w-7" />
                          ) : (
                            ""
                          )}
                        </div>
                      )}
                    </StyledTableCell>
                    <StyledTableCell>{row.work_orders}</StyledTableCell>
                    <StyledTableCell>
                      {row?.billinglatitude && row?.billinglongitude
                        ? distance(row.billinglatitude, row.billinglongitude)
                        : ""}
                    </StyledTableCell>
                    <StyledTableCell>
                      {row.last_serviced_date &&
                      row.last_serviced_date !== "N/A"
                        ? formatDate(row.last_serviced_date)
                        : "N/A"}
                    </StyledTableCell>
                    <StyledTableCell>
                      {formatDateUTC(row.contract_start_date__c)}
                    </StyledTableCell>
                    <StyledTableCell>
                      {formatDateUTC(row.contract_end_date__c)}
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            )}
          </Table>
        </TableContainer>

        {(!fetchedAll || loading) && <Loader />}
        {fetchedAll && !loading && !data.length && (
          <div className="text-center">
            <div className="inline-block">
              <p>{i18n.t("common.empty")}</p>
            </div>
          </div>
        )}

        <Pagination
          page={page}
          setPage={setPage}
          perPage={perPage}
          setPerPage={setPerPage}
          total={total}
        />
      </div>
    </>
  )
}
const useStyles = makeStyles(() =>
  createStyles({
    listContainer: {
      width: "auto",
      marginLeft: 0,
      padding: "10px",
    },
    location: {
      fontWeight: "bold",
      color: "#1695CB",
    },
    active: {
      backgroundColor: "#d7f5e3",
      color: "#4e996c",
      borderRadius: "20px",
      padding: "5px 10px",
    },
    inactive: {
      backgroundColor: "#fed5d5",
      color: "#ff3b5f",
      borderRadius: "20px",
      padding: "5px 10px",
    },
  })
)

export default SitesLists
