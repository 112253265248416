import { useCallback, useEffect, useRef, useState, PropsWithChildren, useReducer, useMemo } from "react"

import EditOutlinedIcon from "@mui/icons-material/EditOutlined"
import CheckCircleIcon from "@mui/icons-material/CheckCircle"
import { Checkbox, IconButton, Modal, Tooltip } from "@material-ui/core"
import Typography from "@mui/material/Typography"
import Box from "@mui/material/Box"
import TextField from "@mui/material/TextField"
import { IMaskInput } from "react-imask"
import { DateTime } from "luxon"

import { useActiveUser, useAuth } from "../../../providers/AuthProvider"
import { useGraphQLService } from "../../../services/graphqlService"
import { ServicePartner } from "../interfaces"
import { Loader } from "../../common/Loader"
import useI18n from "../../../hooks/useI18n"

type SPState = {
  sp: ServicePartner
  glCheck: boolean
  autoCheck: boolean
  wcCheck: boolean
  selectedOptions: string[]
}

const spReducer = (
  state: SPState | null,
  action: {
    type:
      | "updateCarrier"
      | "updateAName"
      | "updateAEmail"
      | "updateAPhone"
      | "updateDiversity"
      | "toggleGL"
      | "toggleAuto"
      | "toggleWC"
      | "resetChecks"
      | "init"
    payload?: string | ServicePartner | null
  }
) => {
  switch (action.type) {
    case "updateCarrier":
      return {
        ...state,
        sp: {
          ...state?.sp,
          gl_carrier: action.payload,
        },
      } as SPState

    case "updateAEmail":
      return {
        ...state,
        sp: {
          ...state?.sp,
          gl_agent_email: action.payload,
        },
      } as SPState

    case "updateAName":
      return {
        ...state,
        sp: {
          ...state?.sp,
          gl_agent_name: action.payload,
        },
      } as SPState

    case "updateAPhone":
      return {
        ...state,
        sp: {
          ...state?.sp,
          gl_agent_phone: action.payload,
        },
      } as SPState

    case "updateDiversity":
      return {
        ...state,
        sp: {
          ...state?.sp,
          diversity: action.payload,
        },
      } as SPState

    case "toggleGL":
      return {
        ...state,
        glCheck: !state?.glCheck,
        selectedOptions: !!state?.glCheck ? state?.selectedOptions.filter((s) => s !== "gl") : [...(state?.selectedOptions ?? []), "gl"],
      } as SPState

    case "toggleAuto":
      return {
        ...state,
        autoCheck: !state?.autoCheck,
        selectedOptions: !!state?.autoCheck ? state?.selectedOptions.filter((s) => s !== "auto") : [...(state?.selectedOptions ?? []), "auto"],
      } as SPState

    case "toggleWC":
      return {
        ...state,
        wcCheck: !state?.wcCheck,
        selectedOptions: !!state?.wcCheck ? state?.selectedOptions.filter((s) => s !== "wc") : [...(state?.selectedOptions ?? []), "wc"],
      } as SPState

    case "resetChecks":
      return {
        ...state,
        wcCheck: false,
        glCheck: false,
        autoCheck: false,
        selectedOptions: [],
      } as SPState

    case "init":
      return { sp: action.payload, glCheck: false, wcCheck: false, autoCheck: false, selectedOptions: [] } as SPState

    default:
      return state
  }
}

const UploadButton = ({
  children,
  type,
  disabled = false,
  classes,
  partnerName,
  sfid,
  onUpload,
  selectedOptions,
}: PropsWithChildren<{
  type: "dei" | "w9" | "gl" | "auto" | "wc" | "coi"
  partnerName: string
  sfid: string
  onUpload: (formData: FormData) => Promise<void>
  classes?: string
  disabled?: boolean
  selectedOptions?: string[]
}>) => {
  const ref = useRef<HTMLInputElement>(null)

  const handleClick = () => ref?.current?.click()

  const handleFileChanges = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.currentTarget.files?.item(0)

    if (file) {
      const formData = new FormData()
      const lastDot = file.name.lastIndexOf(".")
      const fileType = file.name.substring(lastDot + 1)

      formData.append("file", file)
      formData.append("spId", sfid)

      if (selectedOptions && type === "coi") {
        const name = selectedOptions.length > 1 ? "coi" : selectedOptions[0]

        const filename = `${name.toUpperCase()}-${partnerName.replace(/\s/g, "_").replace(/\./g, "")}-${DateTime.now()
          .toISO()
          .replace(/[:.]/g, "_")}.${fileType}`

        formData.append("objectType", name)

        if (selectedOptions.length > 1) {
          formData.append("documentTypes", selectedOptions.join(","))
        }

        formData.append("filename", filename)
      } else {
        const filename = `${type.toUpperCase()}-${partnerName.replace(/\s/g, "_").replace(/\./g, "")}-${DateTime.now()
          .toISO()
          .replace(/[:.]/g, "_")}.${fileType}`

        formData.append("objectType", type)
        formData.append("filename", filename)
      }

      console.log("va a subir")
      await onUpload(formData)
    }
  }

  return (
    <div
      onClick={handleClick}
      className={
        `flex h-9 w-[13rem] cursor-pointer items-center justify-center rounded-full p-4 font-semibold shadow-lg ${
          disabled ? "bg-case-d-gray text-case-gray opacity-50" : "bg-case-grad-from text-white"
        }` + (classes ? ` ${classes}` : "")
      }
    >
      <span>{children}</span>
      <input
        disabled={disabled}
        ref={ref}
        type="file"
        className="hidden"
        onClick={({ target }: any) => {
          target.value = null
        }}
        onChange={handleFileChanges}
        accept="application/msword, text/plain, application/pdf, .docx, .pdf"
      />
    </div>
  )
}

const ComplianceDashboard = () => {
  const [state, dispatch] = useReducer(spReducer, null)
  const [isLoading, setLoading] = useState(false)
  const [feedback, setFeedback] = useState<{ visible: boolean; message: string }>({ visible: false, message: "" })
  const [isEdittingCarrier, setIsEdittingCarrier] = useState(false)
  const [isEdittingAgentName, setIsEdittingAgentName] = useState(false)
  const [isEdittingAgentEmail, setIsEdittingAgentEmail] = useState(false)
  const [isEdittingAgentPhone, setIsEdittingAgentPhone] = useState(false)

  const { sfId: sfid, partnerName } = useActiveUser()
  const { isCanada } = useAuth()
  const { i18n } = useI18n()
  const graphqlService = useGraphQLService()

  const diversityChecks = [
    {
      title: i18n.t("compliance.diversityChecks.MBE.title"),
      alias: i18n.t("compliance.diversityChecks.MBE.alias"),
      details: i18n.t("compliance.diversityChecks.MBE.details"),
    },
    {
      title: i18n.t("compliance.diversityChecks.VBE.title"),
      alias: i18n.t("compliance.diversityChecks.VBE.alias"),
      details: i18n.t("compliance.diversityChecks.VBE.details"),
    },
    {
      title: i18n.t("compliance.diversityChecks.WBE.title"),
      alias: i18n.t("compliance.diversityChecks.WBE.alias"),
      details: i18n.t("compliance.diversityChecks.WBE.details"),
    },
    {
      title: i18n.t("compliance.diversityChecks.LGBTQ.title"),
      alias: i18n.t("compliance.diversityChecks.LGBTQ.alias"),
      details: i18n.t("compliance.diversityChecks.LGBTQ.details"),
    },
    {
      title: i18n.t("compliance.diversityChecks.IABE.title"),
      alias: i18n.t("compliance.diversityChecks.IABE.alias"),
      details: i18n.t("compliance.diversityChecks.IABE.details"),
    },
    {
      title: i18n.t("compliance.diversityChecks.none.title"),
      alias: i18n.t("compliance.diversityChecks.none.alias"),
      details: i18n.t("compliance.diversityChecks.none.details"),
    },
    {
      title: i18n.t("compliance.diversityChecks.decline.title"),
      alias: i18n.t("compliance.diversityChecks.decline.alias"),
      details: i18n.t("compliance.diversityChecks.decline.details"),
    },
  ]

  const handleUpdate = useCallback(
    async (diversity?: string | null) => {
      try {
        setLoading(true)

        await graphqlService.updateServicePartnerEquipment({
          sfid,
          values: {
            gl_carrier: state?.sp.gl_carrier,
            gl_agent_name: state?.sp.gl_agent_name,
            gl_agent_email: state?.sp.gl_agent_email,
            gl_agent_phone: state?.sp.gl_agent_phone,
            diversity_opt_out: state?.sp.diversity_opt_out,
            diversity: diversity ?? state?.sp.diversity,
          },
        })
      } catch (error) {
        throw new Error(`Error on updating SP: ${error}`)
      } finally {
        setLoading(false)
      }
    },
    [graphqlService, sfid, state]
  )

  const getPartnerData = useCallback(
    async (signal?: AbortSignal) => {
      try {
        const response = await graphqlService.getPartnerBySfid({ sfid }, signal)
        dispatch({ type: "init", payload: response as ServicePartner })
      } catch (error) {
        throw new Error(`Cannot find SP due the following error: ${error}`)
      }
    },
    [graphqlService, sfid]
  )

  const carrierInputField = useMemo(
    () => (
      <>
        <TextField
          disabled={!isEdittingCarrier}
          variant="outlined"
          size="small"
          onChange={({ target: { value } }) => dispatch({ type: "updateCarrier", payload: value })}
          value={state?.sp.gl_carrier}
          onKeyDown={async (e) => {
            if (e.key === "Enter") {
              await handleUpdate()

              setIsEdittingCarrier(false)
            }
          }}
          type="text"
          style={{
            backgroundColor: "#FFF",
            border: "none",
            borderRadius: "5px",
          }}
          className="m-1 max-w-[24rem] p-1"
        />
        <IconButton
          className="w-min"
          onClick={async () => {
            if (isEdittingCarrier) {
              await handleUpdate()

              setIsEdittingCarrier(false)

              return
            }

            setIsEdittingCarrier(!isEdittingCarrier)
          }}
        >
          {isEdittingCarrier ? <CheckCircleIcon className="text-case-grad-from" /> : <EditOutlinedIcon className="text-case-grad-from" />}
        </IconButton>
      </>
    ),
    [handleUpdate, isEdittingCarrier, state?.sp.gl_carrier]
  )

  const agentNameInputField = useMemo(
    () => (
      <>
        <TextField
          disabled={!isEdittingAgentName}
          variant="outlined"
          size="small"
          onChange={({ target: { value } }) => dispatch({ type: "updateAName", payload: value })}
          value={state?.sp.gl_agent_name}
          onKeyDown={async (e) => {
            if (e.key === "Enter") {
              await handleUpdate()

              setIsEdittingAgentName(false)
            }
          }}
          type="text"
          style={{
            backgroundColor: "#FFF",
            border: "none",
            borderRadius: "5px",
          }}
          className="m-1 max-w-[24rem] p-1"
        />
        <IconButton
          className="w-min"
          onClick={async () => {
            if (isEdittingAgentName) {
              await handleUpdate()

              setIsEdittingAgentName(false)

              return
            }

            setIsEdittingAgentName(!isEdittingAgentName)
          }}
        >
          {isEdittingAgentName ? <CheckCircleIcon className="text-case-grad-from" /> : <EditOutlinedIcon className="text-case-grad-from" />}
        </IconButton>
      </>
    ),
    [handleUpdate, isEdittingAgentName, state?.sp.gl_agent_name]
  )

  const agentEmailInputField = useMemo(
    () => (
      <>
        <TextField
          disabled={!isEdittingAgentEmail}
          variant="outlined"
          size="small"
          onChange={({ target: { value } }) => dispatch({ type: "updateAEmail", payload: value })}
          value={state?.sp.gl_agent_email}
          onKeyDown={async (e) => {
            if (e.key === "Enter") {
              await handleUpdate()

              setIsEdittingAgentEmail(false)
            }
          }}
          type="text"
          style={{
            backgroundColor: "#FFF",
            border: "none",
            borderRadius: "5px",
          }}
          className="m-1 max-w-[24rem] p-1"
        />
        <IconButton
          className="w-min"
          onClick={async () => {
            if (isEdittingAgentEmail) {
              await handleUpdate()

              setIsEdittingAgentEmail(false)

              return
            }

            setIsEdittingAgentEmail(!isEdittingAgentEmail)
          }}
        >
          {isEdittingAgentEmail ? <CheckCircleIcon className="text-case-grad-from" /> : <EditOutlinedIcon className="text-case-grad-from" />}
        </IconButton>
      </>
    ),
    [handleUpdate, isEdittingAgentEmail, state?.sp.gl_agent_email]
  )

  const agentPhoneInputField = useMemo(
    () => (
      <>
        <IMaskInput
          className="max-w-[24rem] rounded-[3px] border border-[#C5C5C5] px-3 py-2 text-sm font-medium disabled:bg-white disabled:text-[#AEAEAE]"
          mask="###-###-####"
          definitions={{
            "#": /[0-9]/,
          }}
          disabled={!isEdittingAgentPhone}
          value={state?.sp.gl_agent_phone}
          onKeyDown={async (e) => {
            if (e.key === "Enter") {
              await handleUpdate()

              setIsEdittingAgentPhone(false)
            }
          }}
          onAccept={(value) => dispatch({ type: "updateAPhone", payload: value })}
        />
        <IconButton
          className="w-min"
          onClick={async () => {
            if (isEdittingAgentPhone) {
              await handleUpdate()

              setIsEdittingAgentPhone(false)

              return
            }

            setIsEdittingAgentPhone(!isEdittingAgentPhone)
          }}
        >
          {isEdittingAgentPhone ? <CheckCircleIcon className="text-case-grad-from" /> : <EditOutlinedIcon className="text-case-grad-from" />}
        </IconButton>
      </>
    ),
    [handleUpdate, isEdittingAgentPhone, state?.sp.gl_agent_phone]
  )

  useEffect(() => {
    const controller = new AbortController()

    getPartnerData(controller.signal)

    return () => controller.abort()
  }, [getPartnerData])

  if (!state) return <Loader />

  return (
    <div className="flex flex-col">
      <Modal open={isLoading}>
        <Box
          sx={{
            position: "absolute" as "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 200,
            bgcolor: "background.paper",
            borderRadius: 5,
            boxShadow: 24,
            p: 4,
          }}
        >
          <Loader />
        </Box>
      </Modal>

      <Modal open={feedback.visible} onClose={() => setFeedback({ ...feedback, visible: false })}>
        <Box
          sx={{
            position: "absolute" as "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            borderRadius: 5,
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography variant="h5" component="h2" sx={{ alignSelf: "center", textAlign: "center" }}>
            {feedback.message.toLowerCase().includes("error")
              ? i18n.t("complianceDashboard.uploadError")
              : i18n.t("complianceDashboard.uploadSuccess")}
          </Typography>
        </Box>
      </Modal>

      <div className="my-4 flex flex-row items-center justify-between gap-4">
        <span className="text-center text-4xl font-bold text-case-grad-from">{state.sp.name}</span>
        <span className="text-center text-4xl font-bold text-case-grad-from">{i18n.t("breadcrumbs.complianceDashboard")}</span>
      </div>

      <div className="my-8 ml-8 grid grid-cols-4 gap-4">
        <>
          <span className="mb-10 text-4xl">{i18n.t("compliance.insuranceStatus")}</span>
          <span
            className={`col-span-2 text-4xl font-bold ${
              state.sp.insurance_status?.toLowerCase() === "compliant"
                ? "text-[#34C552]"
                : state.sp.insurance_status?.toLowerCase() === "unknown"
                ? "text-[#000000]"
                : "text-[#FF1744]"
            }`}
          >
            {state.sp.insurance_status ? state.sp.insurance_status : i18n.t("compliance.noStatusProvided")}
          </span>
          <a
            className="mr-[48px] flex flex-col justify-end"
            href={isCanada ? "./coiSampleLFX.pdf" : "./coiSample.pdf"}
            target="_blank"
            rel="noreferrer"
          >
            <div className="flex h-9 cursor-pointer flex-col items-center justify-center rounded-full bg-case-grad-from px-4 py-5 shadow-lg">
              <span className="font-semibold text-white">{i18n.t("compliance.sampleSnowCertificate")}</span>
            </div>
            <span className="w-9/12 self-center px-2 py-4 text-center text-sm italic text-case-d-gray">
              {i18n.t("compliance.provideToInsuranceAgent")}
            </span>
          </a>
        </>

        {state.sp.insurance_noncompliance && (
          <>
            <span className="text-xl">{i18n.t("complance.nonComplianceReason")}</span>
            <span className="col-span-3 mb-4 whitespace-pre-line">
              {state.sp.insurance_noncompliance
                .split(";")
                .map((s) => `- ${s}.`)
                .join("\n")}
            </span>
          </>
        )}

        <>
          <div className="col-span-2 flex flex-col">
            <p className="mb-2 text-xl">{i18n.t("compliance.complianceNotes")}</p>
            <TextField
              multiline
              disabled
              variant="standard"
              value={state.sp.compliance_note_to_sp}
              sx={{
                "& .MuiInputBase-input.Mui-disabled": {
                  WebkitTextFillColor: "#000000",
                },
                backgroundColor: "lightGray",
                borderRadius: "0.375rem",
                border: "1px solid gray",
                padding: "8px",
                height: "100%",
              }}
              InputProps={{
                disableUnderline: true,
              }}
            />
          </div>

          <div className="col-span-2 mr-8">
            <p className="mb-2 text-xl">{i18n.t("compliance.complianceUploads")}</p>
            <div className="flex h-min flex-col justify-between overflow-auto whitespace-nowrap rounded-md border p-4">
              <>
                <div className="flex flex-row items-center">
                  <Checkbox className="w-min" color="primary" checked={state.glCheck ?? false} onChange={(_) => dispatch({ type: "toggleGL" })} />
                  <span>{i18n.t("compliance.generalLiabilityInsurance")}</span>
                </div>

                <div className="flex flex-row items-center">
                  <Checkbox className="w-min" color="primary" checked={state.autoCheck ?? false} onChange={(_) => dispatch({ type: "toggleAuto" })} />
                  <span>{i18n.t("compliance.autoInsurance")}</span>
                </div>

                <div className="flex flex-row items-center">
                  <Checkbox className="w-min" color="primary" checked={state.wcCheck ?? false} onChange={(_) => dispatch({ type: "toggleWC" })} />
                  <span>{i18n.t("compliance.workmansCompInsurance")}</span>
                </div>
              </>

              <div className="flex justify-end">
                <UploadButton
                  classes="mt-4"
                  onUpload={async (formData) => {
                    try {
                      setLoading(true)
                      await graphqlService.uploadDocument(formData)

                      setFeedback({ visible: true, message: "Success" })

                      dispatch({ type: "resetChecks" })
                    } catch (error) {
                      setFeedback({ visible: true, message: "Error" })

                      throw new Error(`Error on upload document: ${error}`)
                    } finally {
                      setLoading(false)
                    }
                  }}
                  partnerName={partnerName}
                  sfid={sfid}
                  disabled={!state.autoCheck && !state.glCheck && !state.wcCheck}
                  selectedOptions={state?.selectedOptions}
                  type="coi"
                >
                  {i18n.t("common.upload")}
                </UploadButton>
              </div>
            </div>
          </div>
        </>

        <>
          <div className="mb-4 flex flex-col items-start justify-start">
            <span className="text-xl">{i18n.t("compliance.w9onFile")}</span>
            <span className="text-sm font-extralight italic">{i18n.t("compliance.w9note")}</span>
          </div>
          <span>{state.sp.w_9_on_file ? "Yes" : "No"}</span>
          <div className="col-span-2 mr-[48px] flex justify-end">
            <UploadButton
              onUpload={async (formData) => {
                try {
                  setLoading(true)
                  await graphqlService.uploadDocument(formData)

                  setFeedback({ visible: true, message: i18n.t("common.success") })
                } catch (error) {
                  setFeedback({ visible: true, message: i18n.t("common.error") })

                  throw new Error(`Error on upload document: ${error}`)
                } finally {
                  setLoading(false)
                }
              }}
              partnerName={partnerName}
              sfid={sfid}
              type="w9"
            >
              {i18n.t("compliance.uploadW9")}
            </UploadButton>
          </div>
        </>

        <>
          <span className="mb-4 text-xl">{i18n.t("compliance.glEndDate")}</span>
          <span className="col-span-3">
            {state.sp.general_liability_policy_end_date
              ? DateTime.fromSQL(state.sp.general_liability_policy_end_date).toFormat("MM/dd/yyyy")
              : "Invalid Date"}
          </span>
        </>

        <>
          <div className="col-span-4 grid grid-cols-4 items-center gap-4">
            <span className="text-xl">{i18n.t("compliance.glCarrier")}</span>
            {carrierInputField}
          </div>
          <div className="col-span-4 grid grid-cols-4 items-center gap-4">
            <span className="text-xl">{i18n.t("compliance.glAgentName")}</span>
            {agentNameInputField}
          </div>
          <div className="col-span-4 grid grid-cols-4 items-center gap-4">
            <span className="text-xl">{i18n.t("compliance.glAgentEmail")}</span>
            {agentEmailInputField}
          </div>
          <div className="col-span-4 mb-10 grid grid-cols-4 items-center gap-4">
            <span className="text-xl">{i18n.t("compliance.glAgentPhone")}</span>
            {agentPhoneInputField}
          </div>
        </>

        <>
          <span className="mb-4 text-xl">{i18n.t("compliance.autoPolicyEndDate")}</span>
          <span className="col-span-3">
            {state.sp.auto_policy_end_date ? DateTime.fromSQL(state.sp.auto_policy_end_date).toFormat("MM/dd/yyyy") : "Invalid Date"}
          </span>
        </>

        <>
          <span className="text-xl">{i18n.t("compliance.taxID1099")}</span>
          <span className="col-span-3">{state.sp.tax_id_1099 ? "Yes" : "No"}</span>
        </>
      </div>

      <div className="mx-8 mb-8 grid grid-cols-4 rounded-md border p-2">
        <>
          <span className="col-span-3 mb-4 text-xl">{i18n.t("compliance.companyDiversity")}</span>
          <div className="relative flex flex-col gap-2">
            <div className="flex justify-end">
              <UploadButton
                onUpload={async (formData) => {
                  try {
                    setLoading(true)
                    await graphqlService.uploadDocument(formData)

                    setFeedback({ visible: true, message: i18n.t("common.success") })
                  } catch (error) {
                    setFeedback({ visible: true, message: i18n.t("common.error") })

                    throw new Error(`Error on upload document: ${error}`)
                  } finally {
                    setLoading(false)
                  }
                }}
                partnerName={partnerName}
                sfid={sfid}
                type="dei"
              >
                {i18n.t("compliance.uploadDEI")}
              </UploadButton>
              <span className="absolute top-10 w-9/12 px-2 py-4 text-center text-sm italic text-case-d-gray">
                {i18n.t("compliance.uploadDiverseCertification")}
              </span>
            </div>
          </div>
        </>

        <>
          {diversityChecks.map((diversity, index) => (
            <div key={index} className="col-span-4 flex w-min flex-row items-center whitespace-nowrap">
              <>
                <Checkbox
                  className="w-min"
                  color="primary"
                  checked={state.sp.diversity?.trim().toLowerCase().includes(diversity.alias.trim().toLowerCase())}
                  onChange={({ target: { checked } }) => {
                    const curr = state.sp.diversity?.split(";") ?? []
                    let newDiversity: string | null = null

                    if (checked) {
                      curr?.push(diversity.alias)
                      newDiversity = curr?.filter((d) => d?.length > 0)?.join(";") ?? null

                      dispatch({ type: "updateDiversity", payload: newDiversity })
                    } else {
                      newDiversity =
                        curr?.filter((d) => d?.length > 0 && d.trim().toLowerCase() !== diversity.alias.trim().toLowerCase()).join(";") ?? null

                      dispatch({
                        type: "updateDiversity",
                        payload: newDiversity,
                      })
                    }

                    handleUpdate(newDiversity)
                  }}
                />
                <Tooltip title={diversity.details} placement="right">
                  <span>{diversity.title}</span>
                </Tooltip>
              </>
            </div>
          ))}
        </>
      </div>
    </div>
  )
}

export default ComplianceDashboard
