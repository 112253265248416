import React, { useEffect, useState } from "react"
import { makeStyles, createStyles, Grid, Tooltip } from "@material-ui/core"
import { dayDiff, monthDiff, monthName, textSimilarity } from "../../../utils/Util"
import useI18n from "../../../hooks/useI18n"
import TranslateIcon from "@mui/icons-material/Translate"
import { useCloudTranslation } from "../../../hooks/useCloudTranslation"

export const BidServiceMatrix = (props: any) => {
  const [services, setServices] = useState<any[]>([])
  const [firstDate, setFirstDate] = useState("")
  const [totalDays, setTotalDays] = useState(0)
  const [months, setMonths] = useState<any[]>([])
  const [bid, setBid] = useState<any>({})
  const classes = useStyles()
  const { i18n } = useI18n()
  const { translateText } = useCloudTranslation()

  useEffect(() => {
    setServices(props?.details?.groupedKeys || [])
    getDates(props?.details?.details || [])
    setBid(props?.details)
  }, [props])

  const getDates = (details: any[]) => {
    let firstDateString = ""
    let lastDateString = ""
    details.map((d) => {
      if (!firstDateString) {
        firstDateString = d.service_start_date || ""
      } else if (d.service_start_date) {
        let date1 = new Date(firstDateString)
        let date2 = new Date(d.service_start_date)
        firstDateString = date1 < date2 ? firstDateString : d.service_start_date
      }

      if (!lastDateString) {
        lastDateString = d.service_end_date || ""
      } else if (d.service_end_date) {
        let date1 = new Date(lastDateString)
        let date2 = new Date(d.service_end_date)
        lastDateString = date1 > date2 ? lastDateString : d.service_end_date
      }
    })

    if (!firstDateString || !lastDateString) {
      if (firstDateString) {
        let d = new Date(firstDateString)
        let year = d.getFullYear()
        let month = d.getMonth()
        let day = d.getDate()
        lastDateString = new Date(year + 1, month, day).toDateString()
      } else if (lastDateString) {
        let d = new Date(lastDateString)
        let year = d.getFullYear()
        let month = d.getMonth()
        let day = d.getDate()
        firstDateString = new Date(year - 1, month, day).toDateString()
      } else {
        let startContract = props?.details?.contract_start_date
        let endContract = props?.details?.contract_end_date
        if (!startContract && !endContract) {
          let d = new Date()
          let year = d.getFullYear()
          let month = d.getMonth()
          let day = d.getDate()
          let date1 = new Date(year, month, day)
          let date2 = new Date(year, month, day)
          let d1 = date1.setMonth(date1.getMonth() - 6)
          let d2 = date2.setMonth(date2.getMonth() + 6)
          firstDateString = new Date(d1).toDateString()
          lastDateString = new Date(d2).toDateString()
        } else if (!!startContract && !!endContract) {
          firstDateString = startContract
          lastDateString = endContract
        } else if (!!startContract) {
          firstDateString = startContract
          let d = new Date(firstDateString)
          let year = d.getFullYear()
          let month = d.getMonth()
          let day = d.getDate()
          lastDateString = new Date(year + 1, month, day).toDateString()
        } else {
          lastDateString = endContract
          let d = new Date(lastDateString)
          let year = d.getFullYear()
          let month = d.getMonth()
          let day = d.getDate()
          firstDateString = new Date(year - 1, month, day).toDateString()
        }
      }
    }

    let date1 = new Date(firstDateString)
    let date2 = new Date(lastDateString)
    let d1 = date1.setMonth(date1.getMonth() + 1)
    let d2 = date2.setMonth(date2.getMonth() + 1)
    let d22 = new Date(d2).setDate(0)
    let d11 = new Date(d1).setDate(0)
    firstDateString = new Date(d11).toDateString()
    lastDateString = new Date(d22).toDateString()

    let months = monthDiff(firstDateString, lastDateString)
    setFirstDate(firstDateString)
    let totalDays = dayDiff(firstDateString, lastDateString)
    setTotalDays(totalDays)
    let firstMonth = new Date(firstDateString)
    let monthIndex = firstMonth.getMonth() + 1
    let m: { name: string }[] = []
    for (let i = 0; i < months; i++) {
      let newIndex = monthIndex < 12 ? monthIndex : monthIndex - ~~(monthIndex / 12) * 12
      m.push({
        name: i18n.t(`common.monthShortNames.${monthName[newIndex]?.toLowerCase()}`),
      })
      monthIndex++
    }

    setMonths(m)
  }

  const getWidth = (service: any) => {
    let days = dayDiff(service.service_start_date, service.service_end_date)

    return days / totalDays
  }

  const getStartDate = (service: any) => {
    let days = dayDiff(firstDate, service.service_start_date)

    return days / totalDays
  }

  const getToday = () => {
    let days = dayDiff(firstDate, new Date().toDateString())
    return days / totalDays
  }

  const getWidthMonth = (m: string) => {
    let days = 30
    switch (m) {
      case "Jan":
      case "Mar":
      case "May":
      case "Jul":
      case "Aug":
      case "Oct":
      case "Dec":
        days = 31
        break
      case "Apr":
      case "Jun":
      case "Sep":
      case "Nov":
        days = 30
        break
      case "Feb":
        days = 28
        break
      default: {
        break
      }
    }

    return days / totalDays
  }

  return (
    <div className={classes.grid}>
      <Grid container>
        <Grid item xs={3}>
          <p className={classes.title}>{i18n.t("common.services")}</p>
        </Grid>
        <Grid item xs={9}>
          <div className="flex flex-row items-center ">
            {months?.map((m: any, i: any) => (
              <div
                key={i}
                className="flex flex-col items-center"
                style={{
                  width: `${getWidthMonth(m.name) * 100}%`,
                }}
              >
                <p className={classes.title}>{m.name}</p>
              </div>
            ))}
          </div>
        </Grid>
        <div className={classes.scrollable}>
          <Grid container>
            {services?.map((service: any, i: any) => (
              <React.Fragment key={i}>
                <Grid item xs={3} className={classes.borderServiceTitle}>
                  <div className="flex flex-row items-center justify-between gap-2">
                    <Tooltip title={service?.name} placement="top-start">
                      <p
                        className={
                          classes.serviceName +
                          " " +
                          (service?.name === "Plow"
                            ? classes.plowBorder
                            : service?.name === "Shovel"
                            ? classes.shovelBorder
                            : service?.name === "Shovel Municipal Walk"
                            ? classes.shovelMBorder
                            : service?.name === "Shovel Store Walk"
                            ? classes.shovelMBorder
                            : service?.name === "De-Ice"
                            ? classes.deIceBorder
                            : service?.name === "De-Ice Lot"
                            ? classes.deIceLBorder
                            : service?.name === "De-Ice Walk"
                            ? classes.deIceWBorder
                            : service?.name === "De-Ice Store Walk"
                            ? classes.deIceSBorder
                            : service?.name === "De-Ice Municipal Walk"
                            ? classes.deIceMBorder
                            : service?.name === "Turn Mulch"
                            ? classes.turnMBorder
                            : service?.name === "Additional Mulch"
                            ? classes.AdditionalMBorder
                            : service?.name === "Fertilization"
                            ? classes.fertilizationBorder
                            : service?.name === "Mulch Application"
                            ? classes.mulchABorder
                            : service?.name === "Bed Maintenance"
                            ? classes.bedMBorder
                            : service?.name === "Pruning"
                            ? classes.pruningBorder
                            : service?.name === "Site Clean-Up Spring"
                            ? classes.siteCleanUpSBorder
                            : service?.name === "Site Clean-Up Fall"
                            ? classes.siteCleanUpFBorder
                            : service?.name === "Irrigation Startup"
                            ? classes.irrigationSBorder
                            : service?.name === "Irrigation Winterization"
                            ? classes.irrigationWBorder
                            : service?.name === "Brush Hogging"
                            ? classes.brushBorder
                            : service?.name === "Seasonal"
                            ? classes.seasonalBorder
                            : service?.name === "Extra"
                            ? classes.extraBorder
                            : service?.name === "Site Clean-Up"
                            ? classes.siteCleanUpBorder
                            : service?.name === "Irrigation"
                            ? classes.irrigationBorder
                            : service?.name === "Irrigation Inspection"
                            ? classes.irrigationIBorder
                            : service?.name === "Irrigation Repair"
                            ? classes.irrigationRBorder
                            : service?.name === "Gutter Cleaning"
                            ? classes.gutterBorder
                            : service?.name === "Scheduled Maintenance"
                            ? classes.scheduledMBorder
                            : service?.name === "Hourly"
                            ? classes.hourlyBorder
                            : service?.name === "Trash"
                            ? classes.trashBorder
                            : service?.name === "Sweeping"
                            ? classes.sweepinBorder
                            : bid?.service_line === "Snow"
                            ? classes.snowBorder
                            : bid?.service_line === "Land"
                            ? classes.landscapeBorder
                            : bid?.service_line === "Sweeping"
                            ? classes.sweepingBorder
                            : classes.janitorialBorder)
                        }
                      >
                        {service?.name}
                      </p>
                    </Tooltip>
                    {i18n.locale !== "en" && service?.name && (
                      <Tooltip title={i18n.t("common.translate")} placement="top-start">
                        <TranslateIcon
                          className={`mr-1 transform cursor-pointer text-right transition duration-75 ease-in-out hover:scale-125 ${
                            service.wasTranslated ? "cursor-default text-slate-300 hover:scale-100" : "text-black"
                          }`}
                          onClick={async (e) => {
                            e.stopPropagation()

                            if (service.wasTranslated) return

                            const translation = await translateText(service.name)

                            if (translation) {
                              service.name = translation || service.name

                              if (service.details) {
                                for (let index = 0; index < service.details.length; index++) {
                                  const s = service.details[index]

                                  const s_translation = await translateText(s.bid_details_title)

                                  if (s_translation) {
                                    s.bid_details_title = s_translation || s.bid_details_title
                                  }
                                }
                              }

                              service.wasTranslated = true
                              props.updateDetails && props.updateDetails()
                            }
                          }}
                        />
                      </Tooltip>
                    )}
                  </div>
                </Grid>
                <Grid item xs={9} className={classes.border}>
                  <div className="relative flex flex-row items-center">
                    {months?.map((m: any, j: any) => (
                      <React.Fragment key={j}>
                        <div
                          className={classes.gridSchedule}
                          style={{
                            width: `${getWidthMonth(m.name) * 100}%`,
                          }}
                        >
                          .
                        </div>
                      </React.Fragment>
                    ))}

                    <div className={classes.distance}>
                      {service?.details?.map((d: any, j: any) => (
                        <span
                          key={j}
                          className={
                            classes.spanDistance +
                            " mt-2 rounded bg-case-grad-from px-2 py-1 " +
                            (service?.name === "Plow"
                              ? classes.plow
                              : service?.name === "Shovel"
                              ? classes.shovel
                              : service?.name === "Shovel Municipal Walk"
                              ? classes.shovelM
                              : service?.name === "Shovel Store Walk"
                              ? classes.shovelM
                              : service?.name === "De-Ice"
                              ? classes.deIce
                              : service?.name === "De-Ice Lot"
                              ? classes.deIceL
                              : service?.name === "De-Ice Walk"
                              ? classes.deIceW
                              : service?.name === "De-Ice Store Walk"
                              ? classes.deIceS
                              : service?.name === "De-Ice Municipal Walk"
                              ? classes.deIceM
                              : service?.name === "Turn Mulch"
                              ? classes.turnM
                              : service?.name === "Additional Mulch"
                              ? classes.AdditionalM
                              : service?.name === "Fertilization"
                              ? classes.fertilization
                              : service?.name === "Mulch Application"
                              ? classes.mulchA
                              : service?.name === "Bed Maintenance"
                              ? classes.bedM
                              : service?.name === "Pruning"
                              ? classes.pruning
                              : service?.name === "Site Clean-Up Spring"
                              ? classes.siteCleanUpS
                              : service?.name === "Site Clean-Up Fall"
                              ? classes.siteCleanUpF
                              : service?.name === "Irrigation Startup"
                              ? classes.irrigationS
                              : service?.name === "Irrigation Winterization"
                              ? classes.irrigationW
                              : service?.name === "Brush Hogging"
                              ? classes.brush
                              : service?.name === "Seasonal"
                              ? classes.seasonal
                              : service?.name === "Extra"
                              ? classes.extra
                              : service?.name === "Site Clean-Up"
                              ? classes.siteCleanUp
                              : service?.name === "Irrigation"
                              ? classes.irrigation
                              : service?.name === "Irrigation Inspection"
                              ? classes.irrigationI
                              : service?.name === "Irrigation Repair"
                              ? classes.irrigationR
                              : service?.name === "Gutter Cleaning"
                              ? classes.gutter
                              : service?.name === "Scheduled Maintenance"
                              ? classes.scheduledM
                              : service?.name === "Hourly"
                              ? classes.hourly
                              : service?.name === "Trash"
                              ? classes.trash
                              : service?.name === "Sweeping"
                              ? classes.sweepin
                              : bid?.service_line === "Snow"
                              ? classes.snow
                              : bid?.service_line === "Land"
                              ? classes.landscape
                              : bid?.service_line === "Sweeping"
                              ? classes.sweeping
                              : classes.janitorial)
                          }
                          style={{
                            width: `${(getWidth(d) > 1 ? 1 : getWidth(d)) * 100}%`,
                            marginLeft: `${getStartDate(d) * 100}%`,
                          }}
                        >
                          {d?.bid_details_title}
                        </span>
                      ))}

                      <span
                        className={classes.spanToday}
                        style={{
                          width: `${(getToday() > 1 ? 1 : getToday() < 0 ? 0 : getToday()) * 100}%`,
                        }}
                      >
                        l
                      </span>
                    </div>
                  </div>
                </Grid>
              </React.Fragment>
            ))}
          </Grid>
        </div>
      </Grid>
    </div>
  )
}

const useStyles = makeStyles(() => {
  return createStyles({
    grid: {
      borderRadius: "5px",
      background: "#FFFFFF",
      boxShadow: "0px 0px 3px 1px rgb(0 0 0 / 20%)",
      paddingBottom: "2px",
    },
    borderServiceTitle: {
      borderTop: "1px solid #d4d4d4",
      borderRight: "1px solid #d4d4d4",
    },
    border: {
      borderTop: "1px solid #d4d4d4",
    },
    serviceName: {
      color: "#0F2150",
      fontWeight: 700,
      padding: "5px",
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
    },
    gridSchedule: {
      color: "white",
      fontSize: "25px",
      borderRight: "1px solid #d4d4d4",
    },
    title: {
      color: "gray",
      fontWeight: 700,
      padding: "5px",
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
    },
    distance: {
      color: "white",
    },
    spanDistance: {
      top: 0,
      left: 0,
      border: "none",
      position: "absolute",
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      fontSize: "10px",
    },
    spanToday: {
      borderRight: "2px solid red",
      fontSize: "26px",
      backgroundColor: "rgba(0, 0, 0, 0.2)",
      color: "rgba(0, 0, 0, 0)",
      top: 0,
      left: 0,
      border: "none",
      position: "absolute",
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
    },
    snow: {
      background: "linear-gradient(-45grad, #a0e0f8 0%, #a0e0f8 50%, #95ddf7 50%, #95ddf7 100%)",
      color: "#0F2150",
    },
    landscape: {
      background: "linear-gradient(-45grad, #08543e 0%, #08543e 50%, #005c42 50%, #005c42 100%)",
      color: "white",
    },
    janitorial: {
      background: "linear-gradient(-45grad, #ffe299 0%, #ffe299 50%, #ffe9b3 50%, #ffe9b3 100%)",
      color: "white",
    },
    sweeping: {
      background: "linear-gradient(-45grad, #ffd1da 0%, #ffd1da 50%, #ffccd6 50%, #ffccd6 100%)",
      color: "white",
    },
    snowBorder: {
      borderLeft: "5px solid #a0e0f8",
    },
    landscapeBorder: {
      borderLeft: "5px solid #08543e",
    },
    janitorialBorder: {
      borderLeft: "5px solid #ffe299",
    },
    sweepingBorder: {
      borderLeft: "5px solid #ffd1da",
    },
    scrollable: {
      // maxHeight: "300px",
      // overflow: "hidden",
      // overflowY: "scroll",
      width: "100%",
    },
    plow: {
      backgroundColor: "#0F2150",
      color: "#FFF",
    },
    shovel: {
      backgroundColor: "#4EC7F1",
      color: "#0F2150",
    },
    shovelM: {
      backgroundColor: "#71D2F4",
      color: "#0F2150",
    },
    shovelS: {
      backgroundColor: "#95DDF7",
      color: "#0F2150",
    },
    deIce: {
      backgroundColor: "#5B8F99",
      color: "#FFF",
    },
    deIceL: {
      backgroundColor: "#79BECC",
      color: "#0F2150",
    },
    deIceW: {
      backgroundColor: "#97EEFF",
      color: "#0F2150",
    },
    deIceS: {
      backgroundColor: "#ACF1FF",
      color: "#0F2150",
    },
    deIceM: {
      backgroundColor: "#C1F5FF",
      color: "#0F2150",
    },
    turnM: {
      backgroundColor: "#332B18",
      color: "#FFF",
    },
    AdditionalM: {
      backgroundColor: "#665730",
      color: "#FFF",
    },
    fertilization: {
      backgroundColor: "#998248",
      color: "#FFF",
    },
    mulchA: {
      backgroundColor: "#CCAE60",
      color: "#0F2150",
    },
    bedM: {
      backgroundColor: "#74E0A4",
      color: "#052915",
    },
    pruning: {
      backgroundColor: "#13A253",
      color: "#FFF",
    },
    siteCleanUpS: {
      backgroundColor: "#18CB68",
      color: "#052915",
    },
    siteCleanUpF: {
      backgroundColor: "#0E7A3E",
      color: "#FFF",
    },
    irrigationS: {
      backgroundColor: "#85ADA1",
      color: "#01110D",
    },
    irrigationW: {
      backgroundColor: "#E1EAE8",
      color: "#01110D",
    },
    brush: {
      backgroundColor: "#258C64",
      color: "#FFF",
    },
    seasonal: {
      backgroundColor: "#2EAF7D",
      color: "#092319",
    },
    extra: {
      backgroundColor: "#58BF97",
      color: "#092319",
    },
    siteCleanUp: {
      backgroundColor: "#ABDFCB",
      color: "#092319",
    },
    irrigation: {
      backgroundColor: "#075740",
      color: "#FFF",
    },
    irrigationI: {
      backgroundColor: "#397966",
      color: "#FFF",
    },
    irrigationR: {
      backgroundColor: "#B9D0C9",
      color: "#01110D",
    },
    gutter: {
      backgroundColor: "#424242",
      color: "#FFF",
    },
    scheduledM: {
      backgroundColor: "#18CB68",
      color: "#FFF",
    },
    hourly: {
      backgroundColor: "#8E8E8E",
      color: "#FFF",
    },
    trash: {
      backgroundColor: "#B3B3B3",
      color: "#1A1A1A",
    },
    sweepin: {
      backgroundColor: "#FFE8AE",
      color: "#332B18",
    },

    plowBorder: {
      borderLeft: "5px solid #0F2150",
    },
    shovelBorder: {
      borderLeft: "5px solid #4EC7F1",
    },
    shovelMBorder: {
      borderLeft: "5px solid #71D2F4",
    },
    shovelSBorder: {
      borderLeft: "5px solid #95DDF7",
    },
    deIceBorder: {
      borderLeft: "5px solid #5B8F99",
    },
    deIceLBorder: {
      borderLeft: "5px solid #79BECC",
    },
    deIceWBorder: {
      borderLeft: "5px solid #97EEFF",
    },
    deIceSBorder: {
      borderLeft: "5px solid #ACF1FF",
    },
    deIceMBorder: {
      borderLeft: "5px solid #C1F5FF",
    },
    turnMBorder: {
      borderLeft: "5px solid #332B18",
    },
    AdditionalMBorder: {
      borderLeft: "5px solid #665730",
    },
    fertilizationBorder: {
      borderLeft: "5px solid #998248",
    },
    mulchABorder: {
      borderLeft: "5px solid #CCAE60",
    },
    bedMBorder: {
      borderLeft: "5px solid #74E0A4",
    },
    pruningBorder: {
      borderLeft: "5px solid #13A253",
    },
    siteCleanUpSBorder: {
      borderLeft: "5px solid #18CB68",
    },
    siteCleanUpFBorder: {
      borderLeft: "5px solid #0E7A3E",
    },
    irrigationSBorder: {
      borderLeft: "5px solid #85ADA1",
    },
    irrigationWBorder: {
      borderLeft: "5px solid #E1EAE8",
    },
    brushBorder: {
      borderLeft: "5px solid #258C64",
    },
    seasonalBorder: {
      borderLeft: "5px solid #2EAF7D",
    },
    extraBorder: {
      borderLeft: "5px solid #58BF97",
    },
    siteCleanUpBorder: {
      borderLeft: "5px solid #ABDFCB",
    },
    irrigationBorder: {
      borderLeft: "5px solid #075740",
    },
    irrigationIBorder: {
      borderLeft: "5px solid #397966",
    },
    irrigationRBorder: {
      borderLeft: "5px solid #B9D0C9",
    },
    gutterBorder: {
      borderLeft: "5px solid #424242",
    },
    scheduledMBorder: {
      borderLeft: "5px solid #18CB68",
    },
    hourlyBorder: {
      borderLeft: "5px solid #8E8E8E",
    },
    trashBorder: {
      borderLeft: "5px solid #B3B3B3",
    },
    sweepinBorder: {
      borderLeft: "5px solid #FFE8AE",
    },
  })
})
