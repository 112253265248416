import { Box, Button, createStyles, makeStyles, Modal } from "@material-ui/core"
import { useEffect, useState } from "react"
import { useDebouncedCallback } from "use-debounce"
import { usePartnersService } from "../../../services/partnersService"
import { ReactComponent as CloseIcon } from "./../../../assets/close-w.svg"
import { Loader } from "../../common/Loader"
import { TeamModalProps } from "../desktop/TeamDesktop"
import useI18n from "../../../hooks/useI18n"

export default function DeleteUserModal({
  user,
  openModal,
  closeModal,
  reload,
  isAdmin,
}: TeamModalProps) {
  const partnersService = usePartnersService()
  const classes = useStyles()
  const { i18n } = useI18n()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState("")

  const handleDelete = () => {
    deleteUser()
  }

  const deleteUser = useDebouncedCallback(async (signal?: AbortSignal) => {
    setLoading(true)
    let response: any = null
    if (isAdmin) {
      response = await partnersService.deleteUser(user.id, signal)
    } else {
      response = await partnersService.deleteUserAssociations(
        {
          userId: user.id,
          organizationId: user.organization_roles[0].organization_id,
        },
        signal
      )
    }

    if (response) {
      setLoading(false)
      reload()
      handleClose()
    } else {
      setLoading(false)
      setError("Something went wrong")
    }
  }, 500)

  const handleClose = () => {
    closeModal()
  }

  return (
    <Modal
      open={openModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      onClose={handleClose}
    >
      <Box className={classes.style}>
        <div className={classes.header}>{i18n.t("common.modal.deleteUser.deleteUser")}</div>
        <div className={classes.body}>
          {loading ? (
            <Loader />
          ) : (
            <>
              <p>
                {i18n.t("common.modal.deleteUser.areYouSure")}{" "}
                <span className={classes.user}>
                  {user.email || user.phone_number}
                </span>
                ?
              </p>
              <p>{i18n.t("common.modal.deleteUser.actionCannotBeUndone")}</p>
            </>
          )}
        </div>
        <div className="flex flex-col items-center">
          <Button
            onClick={() => handleDelete()}
            style={{
              marginTop: "80px",
              margin: "auto",
              marginBottom: "10px",
            }}
            className={classes.actionButton + " w-40"}
            disabled={loading}
          >
            {i18n.t("common.delete")}
          </Button>
        </div>

        <Button
          variant="text"
          onClick={handleClose}
          className={classes.imgButton}
        >
          <CloseIcon />
        </Button>
      </Box>
    </Modal>
  )
}

const useStyles = makeStyles(() =>
  createStyles({
    body: {
      padding: "10px",
      textAlign: "center",
      marginBottom: "10px",
    },
    user: {
      fontWeight: "bold",
    },
    style: {
      position: "absolute" as "absolute",
      left: "50%",
      transform: "translate(-50%, 30vh)",
      backgroundColor: "white",
      borderRadius: "5px",
      width: "300px",
      minWidth: "100px",
    },
    actionButton: {
      border: "1px solid #eb4034",
      borderRadius: "100px",
      backgroundColor: "#eb4034",
      color: "#fff",
      "&.Mui-disabled": {
        border: "1px solid #e2e5ea",
        backgroundColor: "#e2e5ea",
        color: "#b2b7c6",
      },
      "&:hover": {
        backgroundColor: "#eb5c52",
      },
    },
    imgButton: {
      background: "red",
      top: 0,
      right: 0,
      border: "none",
      cursor: "pointer",
      position: "absolute",
      textAlign: "center",
      borderRadius: "5px",
      height: "25px",
      minWidth: "25px",
      "&:hover": {
        backgroundColor: "#eb5c52",
      },
    },
    header: {
      padding: "1px",
      paddingLeft: "10px",
      backgroundColor: "lightgray",
      borderTopRightRadius: "5px",
      borderTopLeftRadius: "5px",
      fontWeight: "bold",
      color: "#0F2150",
    },
  })
)
